import { Component, Input, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, ComponentRef, OnChanges } from '@angular/core';
import { UiTable, UiRow, UiCell } from '../ui-renderer.interfaces';
import { UiLabelComponent } from '../ui-components/ui-label/ui-label.component';
import { UiBaseComponent } from '../ui-components/ui-base.component';
import { UiTextAreaComponent } from '../ui-components/ui-text-area/ui-text-area.component';
import { UiImageComponent } from '../ui-components/ui-image/ui-image.component';

@Component({
    selector: 'app-ui-cell',
    templateUrl: 'ui-cell-renderer.component.html',
    styleUrls: ['ui-cell-renderer.component.scss']
})
export class UiCellRendererComponent implements OnInit, OnChanges {
    // tslint:disable-next-line:no-input-rename
    @Input('meta') meta: UiCell;
    // tslint:disable-next-line:no-input-rename
    @Input('data') data: any;
    // tslint:disable-next-line:no-input-rename
    @Input('style-class') styleClass: string;

    // tslint:disable-next-line:no-input-rename
    @Input('use-meta-style') useMetaStyle = false;

    components: any = {};

    repeatInfo: any[] = [];

    @ViewChild('cellBody', { static: true, read: ViewContainerRef })
    cellBody: any;
    private cellContent: ComponentRef<UiBaseComponent>;

    constructor(private cfr: ComponentFactoryResolver) {
        this.components['label'] = UiLabelComponent;
        this.components['text-area'] = UiTextAreaComponent;
        this.components['image'] = UiImageComponent;
    }

    ngOnInit() {

    }

    ngOnChanges() {
        if (this.meta.layout === 'cell') {
            if (typeof this.meta.component === 'string') {
                const component = this.components[this.meta.component];
                const compFactory: any = this.cfr.resolveComponentFactory(component);
                this.cellBody.clear();
                this.cellContent = this.cellBody.createComponent(compFactory);
                this.cellContent.instance.meta = this.meta;
                this.cellContent.instance.data = this.data;
            }
        } else if (this.meta.layout === 'repeat-cell') {
            if (this.data && this.data[this.meta.data.object]) {
                const data: any[] = this.data[this.meta.data.object];
                let noOfCells = data.length;
                const totalColumns = 12;
                if (noOfCells > 3) {
                    noOfCells = 3;
                }
                if (this.meta.config.maxLength > 0) {
                    if (noOfCells > this.meta.config.maxLength) {
                        noOfCells = this.meta.config.maxLength;
                    }
                    this.repeatInfo = [];
                    data.forEach((datum, index) => {
                        if (index < this.meta.config.maxLength) {
                            const repeatInfoItem = {
                                data: datum,
                                meta: {
                                    layout: 'cell',
                                    component: this.meta.component,
                                    data: {
                                        field: this.meta.data.field
                                    },
                                    config: this.meta.config,
                                    styleClass: this.meta.styleClass} as UiCell
                            };
                            if (this.meta.config.titles && this.meta.config.titles.length > index) {
                                repeatInfoItem.meta.data.title = this.meta.config.titles[index];
                            }
                            this.repeatInfo.push(repeatInfoItem);
                        }
                    });
                }

                this.styleClass = `col-sm 12 col-md-${totalColumns / noOfCells}`;
            } else {
                this.styleClass = `col-sm 12`;
            }
        } else if (this.meta.layout === 'repeat-table') {
            if (this.data && this.data[this.meta.data.object]) {
                const data: any[] = this.data[this.meta.data.object];
                let noOfCells = data.length;
                const totalColumns = 12;
                if (noOfCells > 3) {
                    noOfCells = 3;
                }
                if (this.meta.config.maxLength > 0) {
                    if (noOfCells > this.meta.config.maxLength) {
                        noOfCells = this.meta.config.maxLength;
                    }
                    this.repeatInfo = [];
                    data.forEach((datum, index) => {
                        if (index < this.meta.config.maxLength) {
                            const repeatInfoItem = {
                                data: datum,
                                meta: {
                                    layout: 'table',
                                    rows: []
                                },
                                config: {},
                                styleClass: this.meta.styleClass
                            };
                            this.meta.config.fields.forEach((field, jndex) => {
                                const row = {
                                    layout: 'row',
                                    cells: [
                                        {
                                            layout: 'cell',
                                            component: 'label',
                                            data: {
                                                field: field,
                                                title: this.meta.config.titles[field][jndex]
                                            },
                                            styleClass: 'col-xs-12'
                                        }
                                    ]
                                };
                                repeatInfoItem.meta.rows.push(row);
                            });
                            this.repeatInfo.push(repeatInfoItem);
                        }
                    });
                }

                this.styleClass = `col-sm 12 col-md-${totalColumns / noOfCells}`;
            } else {
                this.styleClass = `col-sm 12`;
            }
        }
    }
}
