import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { CampaignModel } from '../models/campaign.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

export class CampaignService extends OdooHttpBaseService {
    baseUrl = 'campaign';

    constructor(injector: Injector) {
        super(injector);
    }

    getByProductCode(productCode: string): Observable<CampaignModel[]> {
        const observable = new Observable<CampaignModel[]>(subscriber => {
            const request: any = {
                productCode: productCode
            };
            this._post(`${this.baseUrl}/get_product_campaigns`, request).subscribe(items => {
                if (items != null) {
                    const campaigns = CampaignModel.getInstancesFromResponse(items);
                    subscriber.next(campaigns);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }
}
