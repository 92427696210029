import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductModel } from '../models/product.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

export class ProductService extends OdooHttpBaseService {
    baseUrl = 'product';

    constructor(injector: Injector) {
        super(injector);
    }

    quickSearch(query: string): Observable<ProductModel[]> {
        const observable = new Observable<ProductModel[]>(subscriber => {
            this._post(`${this.baseUrl}/qsearch`, {query: query}).subscribe(items => {
                if (items != null) {
                    const products = ProductModel.getInstancesFromResponse(items);
                    subscriber.next(products);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getSubscriptionProduct(product: ProductModel): Observable<ProductModel> {
        const observable = new Observable<ProductModel>(subscriber => {
            this._post(`subscription_product/get`, {productId: product.id}).subscribe(responses => {
                if (responses && responses.length > 0) {
                    const subscriptionProduct = ProductModel.getInstanceFromResponse(responses[0]);
                    subscriber.next(subscriptionProduct);
                } else {
                    subscriber.next(product);
                }
            });
        });
        return observable;
    }
}
