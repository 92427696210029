import { BaseModel } from './base.model';
import { SubscriptionTemplateModel } from './subscription.model';

export class ProductModel extends BaseModel {
    id: number;
    code: string;
    defaultCode: string;
    type: string;
    name: string;
    displayName: string;
    description: string;
    descriptionSale: string;
    price: number;
    lstPrice: number;
    listPrice: number;
    standardPrice: number;
    entryFee: number;
    priceExtra: number;
    currency: CurrencyModel;
    uom: UomModel;
    category: ProductCategoryModel;
    saleOk: boolean;
    companyId: number;
    active: boolean;
    isPack: boolean;
    calPackPrice: boolean;
    packProducts: PackProductModel[];
    additionalProducts: ProductModel[];
    subsriptionTemplateId: number;
    subscriptionTemplate: SubscriptionTemplateModel;

    public static getInstanceFromResponse(response: any): ProductModel {
        const model: ProductModel = new ProductModel();
        model.id = BaseModel.getValue(response.id);
        model.code = BaseModel.getValue(response.code);
        model.defaultCode = BaseModel.getValue(response.defaultCode);
        model.name = BaseModel.getValue(response.name);

        if (model.defaultCode != null && model.defaultCode.trim() !== '') {
            model.displayName = model.name + ' - ' + model.defaultCode;
        } else {
            model.displayName = model.name;
        }
        model.type = BaseModel.getValue(response.type);
        model.description = BaseModel.getValue(response.description);
        model.descriptionSale = BaseModel.getValue(response.descriptionSale);
        model.price = BaseModel.getValue(response.price);
        model.lstPrice = BaseModel.getValue(response.lstPrice);
        model.listPrice = BaseModel.getValue(response.listPrice);
        model.entryFee = BaseModel.getValue(response.entryFee);
        model.priceExtra = BaseModel.getValue(response.priceExtra);
        model.currency = CurrencyModel.getInstanceFromDepenentResponse(response);
        model.uom = UomModel.getInstanceFromDepenentResponse(response);
        model.category = ProductCategoryModel.getInstanceFromDepenentResponse(response);
        model.saleOk = response.saleOk;
        model.companyId = BaseModel.getValue(response.companyId);
        model.active = response.active;
        model.isPack = response.isPack;
        model.calPackPrice = response.calPackPrice;
        model.packProducts = PackProductModel.getInstancesFromResponse(response.packProducts);
        model.additionalProducts = ProductModel.getInstancesFromResponse(response.additionalProducts);
        model.subsriptionTemplateId = BaseModel.getValue(response.subsriptionTemplateId);

        return model;
    }

    public static getInstancesFromResponse(responses: any): ProductModel[] {
        const models: ProductModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: ProductModel = ProductModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}


export class CurrencyModel extends BaseModel {
    id: number;
    name: string;
    symbol: string;
    unitLabel: string;

    public static getInstanceFromDepenentResponse(response: any): CurrencyModel {
        const model = new CurrencyModel();
        model.id = BaseModel.getValue(response.currencyId);
        model.name = BaseModel.getValue(response.currencyName);
        model.symbol = BaseModel.getValue(response.currencySymbol);
        model.unitLabel = BaseModel.getValue(response.currencyUnitLabel);

        return model;
    }
}

export class UomModel extends BaseModel {
    id: number;
    name: string;
    public static getInstanceFromDepenentResponse(response: any): UomModel {
        const model = new UomModel();
        model.id = BaseModel.getValue(response.uomId);
        model.name = BaseModel.getValue(response.uomName);

        return model;
    }
}

export class ProductCategoryModel extends BaseModel {
    id: number;
    name: string;
    public static getInstanceFromDepenentResponse(response: any): ProductCategoryModel {
        const model = new ProductCategoryModel();
        model.id = BaseModel.getValue(response.categoryId);
        model.name = BaseModel.getValue(response.categoryName);

        return model;
    }
}

export class PackProductModel extends BaseModel {
    id: number;
    product: ProductModel;
    productUomQty: number;
    listPrice: number;
    public static getInstanceFromResponse(response: any): PackProductModel {
        const model: PackProductModel = new PackProductModel();
        model.id = BaseModel.getValue(response.id);
        model.productUomQty = BaseModel.getValue(response.productUomQty);
        model.listPrice = BaseModel.getValue(response.listPrice);
        model.product = ProductModel.getInstanceFromResponse(response.product);
        return model;
    }
    public static getInstancesFromResponse(responses: any): PackProductModel[] {
        const models: PackProductModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: PackProductModel = PackProductModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}
