import { BaseModel } from './base.model';

export class CampaignModel extends BaseModel {
    id: number;
    companyId: number;
    campaignKey: string;
    campaignName: string;
    isSubCampaign: boolean;
    startDate: Date;
    endDate: Date;
    mainCampaign: CampaignModel;
    callCenters: CampaignCallCenterModel[];
    campaignEffects: CampaignEffectsModel[];
    campaignChannels: CampaignChannelModel[];

    public static getInstanceFromResponse(response: any): CampaignModel {
        const model = new CampaignModel();
        model.id = BaseModel.getValue(response.id);
        model.companyId = BaseModel.getValue(response.companyId);
        model.campaignKey = BaseModel.getValue(response.campaignKey);
        model.campaignName = BaseModel.getValue(response.name);
        model.isSubCampaign = response.isSubCampaign;
        model.startDate = BaseModel.getDate(response.startDate);
        model.endDate = BaseModel.getDate(response.endDate);
        if  (response.mainCampaign) {
            model.mainCampaign = CampaignModel.getInstanceFromResponse(response.mainCampaign);
        }
        model.callCenters = CampaignCallCenterModel.getInstancesFromResponse(response.callCenters);
        model.campaignEffects = CampaignEffectsModel.getInstancesFromResponse(response.campaignEffects);
        model.campaignChannels = CampaignChannelModel.getInstancesFromResponse(response.campaignChannels);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): CampaignModel[] {
        const models: CampaignModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model = CampaignModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export class CampaignCallCenterModel extends BaseModel {

    id: number;
    name: string;
    public static getInstanceFromResponse(response: any): CampaignCallCenterModel {
        const model = new CampaignCallCenterModel();
        model.id = response.id;
        model.name = response.name
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): CampaignCallCenterModel[] {
        const models: CampaignCallCenterModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model = CampaignCallCenterModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}


export class CampaignEffectsModel extends BaseModel {
    id: number;
    productId: number;
    productCode: string;
    productName: string;
    campaignEffectsType: string;
    value: number;
    startInterval: number;
    numberOfIntervals: number;

    public static getInstanceFromResponse(response: any): CampaignEffectsModel {
        const model = new CampaignEffectsModel();
        model.id = BaseModel.getValue(response.id);
        model.productId = BaseModel.getValue(response.productId);
        model.productCode = BaseModel.getValue(response.productCode);
        model.productName = BaseModel.getValue(response.productName);
        model.campaignEffectsType = BaseModel.getValue(response.campaignEffectsType);
        model.value = BaseModel.getValue(response.value);
        model.startInterval = BaseModel.getValue(response.startInterval);
        model.numberOfIntervals = BaseModel.getValue(response.numberOfIntervals);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): CampaignEffectsModel[] {
        const models: CampaignEffectsModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model = CampaignEffectsModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export class CampaignChannelModel extends BaseModel {
    id: number;
    name: string;
    campaign: CampaignModel;

    public static getInstanceFromResponse(response: any): CampaignChannelModel {
        const model = new CampaignChannelModel();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        if (response.campaign) {
            model.campaign = CampaignModel.getInstanceFromResponse(response.campaign);
        }
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): CampaignChannelModel[] {
        const models: CampaignChannelModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model = CampaignChannelModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}
