import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TopMenuComponent } from './top-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18NModule } from '../../../i18n/i18n.module';
import { TranslatorPipe } from '../../../i18n/translator.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        I18NModule
    ],
    declarations: [
        TopMenuComponent
    ],
    exports: [
        TopMenuComponent
    ],
    providers: [
        TranslatorPipe
    ],
    entryComponents: [

    ]
})
export class TopMenuModule {

}
