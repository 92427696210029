import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { PaymentMethodsModel } from './payment-methods.model';
import { ProductModel } from './product.model';

export class SubscriptionModel extends BaseModel {
    id: number;
    referenceId: string;
    name: string;
    partnerId: number;
    partnerName: string;
    partnerReferenceId: string;
    eventPeriodStart: Date;
    startDate: Date;
    endDate: Date;
    activationDate: Date;
    paymentMethod: PaymentMethodsModel;
    orderId: number;
    cancellationStatus: string;
    cancellationDate: Date;
    saleTeamId: number;
    saleTeamName: string;
    agentId: string;
    trackingId: string;
    dateInserted: Date;
    template: SubscriptionTemplateModel;
    marketingCode: string;
    campaignName: string;
    statuses: string[];
    reactivatedId: number;
    reactivatedReferenceId: string;
    productUomQty: number;
    subscriptionItems: SubscriptionItem[];
    invoiceAddress: AddressModel;
    shippingAddress: AddressModel;
    additionalProperties: AdditionalProperty[];
    lstPrice: number;
    processProperties: any;

    canCancel: boolean;
    canBackdatedCancel: boolean;
    canRevoke: boolean;
    canReactivate: boolean;
    canSwitchProduct: boolean;
    canSendBonus: boolean;
    canPayout: boolean;
    canChangePaymentMethod: boolean;
    canChangeProcessProperties: boolean;
    canRefundInvoices: boolean;
    createDate: Date;
    notificationType: string;
    immidiatePaymentDate: Date;
    canMakeImmidiatePayment: boolean;

    public static getInstanceFromResponse(response: any): SubscriptionModel {
        const subscription: SubscriptionModel = new SubscriptionModel();
        subscription.id = BaseModel.getValue(response.id);
        subscription.referenceId = BaseModel.getValue(response.referenceId);
        subscription.name = BaseModel.getValue(response.name);
        subscription.partnerId = BaseModel.getValue(response.partnerId);
        subscription.partnerName = BaseModel.getValue(response.partnerName);
        subscription.partnerReferenceId = BaseModel.getValue(response.partnerReferenceId);
        subscription.eventPeriodStart = BaseModel.getDate(response.eventPeriodStart);
        subscription.startDate = BaseModel.getDate(response.startDate);
        subscription.endDate = BaseModel.getDate(response.endDate);
        subscription.activationDate = BaseModel.getDate(response.activationDate);
        if (response.paymentMethod) {
            subscription.paymentMethod = PaymentMethodsModel.getInstanceFromResponse(response.paymentMethod);
        }
        subscription.orderId = BaseModel.getValue(response.orderId);
        subscription.cancellationStatus = BaseModel.getValue(response.cancellationStatus);
        subscription.cancellationDate = BaseModel.getDate(response.cancellationDate);
        subscription.saleTeamId = BaseModel.getValue(response.saleTeamId);
        subscription.saleTeamName = BaseModel.getValue(response.saleTeamName);
        subscription.agentId = BaseModel.getValue(response.agentId);
        subscription.trackingId = BaseModel.getValue(response.trackingId);
        subscription.dateInserted = BaseModel.getDate(response.dateInserted);
        subscription.template = SubscriptionTemplateModel.getInstanceFromResponse(response.subscriptionTemplate);
        subscription.marketingCode = BaseModel.getValue(response.marketingCode);
        subscription.campaignName = BaseModel.getValue(response.campaignName);
        let statuses = BaseModel.getValue(response.statuses);
        if (typeof statuses === 'string') {
            statuses = JSON.parse(statuses.replace(/'/g, '"'));
        }
        subscription.statuses = statuses;
        subscription.reactivatedId = BaseModel.getValue(response.reactivatedId);
        subscription.reactivatedReferenceId = BaseModel.getValue(response.reactivatedReferenceId);
        subscription.productUomQty = BaseModel.getValue(response.productUomQty);
        subscription.subscriptionItems = SubscriptionItem.getInstancesFromResponse(response.subscriptionItems);
        subscription.invoiceAddress = AddressModel.getInstanceFromResponse(response.invoiceAddress);
        subscription.shippingAddress = AddressModel.getInstanceFromResponse(response.shippingAddress);
        subscription.additionalProperties = AdditionalProperty.getInstancesFromResponse(response.additionalProperties);
        subscription.canCancel = BaseModel.getValue(response.canCancel);
        subscription.canBackdatedCancel = BaseModel.getValue(response.canBackdatedCancel);
        subscription.canRevoke = BaseModel.getValue(response.canRevoke);
        subscription.canReactivate = BaseModel.getValue(response.canReactivate);
        subscription.canSwitchProduct = BaseModel.getValue(response.canSwitchProduct);
        subscription.canSendBonus = BaseModel.getValue(response.canSendBonus);
        subscription.canPayout = BaseModel.getValue(response.canPayout);
        subscription.canChangePaymentMethod = BaseModel.getValue(response.canChangePaymentMethod);
        subscription.lstPrice = BaseModel.getValue(response.lstPrice);
        subscription.processProperties = BaseModel.getValue(response.processProperties);
        if (subscription.processProperties) {
            Object.keys(subscription.processProperties).forEach(property => {
                if (subscription.processProperties[property] === false) {
                    subscription.processProperties[property] = '';
                }
            });
        }
        subscription.canChangeProcessProperties = subscription.processProperties && Object.keys(subscription.processProperties).length > 0;
        subscription.canRefundInvoices = BaseModel.getValue(response.canRefundInvoices);
        subscription.createDate = BaseModel.getDate(response.createDate);
        subscription.notificationType = BaseModel.getValue(response.notificationType);

        return subscription;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionModel[] {
        const subscriptions: SubscriptionModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const subscription: SubscriptionModel = SubscriptionModel.getInstanceFromResponse(response);
                subscriptions.push(subscription);
            });
        }
        return subscriptions;
    }

    public static canPerformAction(subscriptions: any, action: string) {
        const canPeformAction: any = {
            'cancel-subscription': 'canBackdatedCancel',
            'revoke-subscription': 'canRevoke',
            'reactivate-subscription': 'canReactivate',
            'change-product': 'canSwitchProduct',
            'apply-bonus': 'canSendBonus',
            'payout': 'canPayout',
            'change-payment-method': 'canChangePaymentMethod',
            'change-process-properties': 'canChangeProcessProperties',
            'refund-invoices': 'canRefundInvoices',
            'make-immidiate-payment': 'canMakeImmidiatePayment'
        };
        let canPerform = false;
        const canActionProperty = canPeformAction[action];
        if (subscriptions && canActionProperty != null) {
            if (!(subscriptions instanceof Array)) {
                subscriptions = [subscriptions];
            }

            subscriptions.forEach(subscription => {
                if (!canPerform && subscription[canActionProperty]) {
                    canPerform = true;
                }
            });
        }

        return canPerform;
    }

    public static filterSubscriptions(subscriptions: any, action: string) {
        const canPeformAction: any = {
            'cancel-subscription': 'canBackdatedCancel',
            'revoke-subscription': 'canRevoke',
            'reactivate-subscription': 'canReactivate',
            'change-product': 'canSwitchProduct',
            'apply-bonus': 'canSendBonus',
            'payout': 'canPayout',
            'change-payment-method': 'canChangePaymentMethod',
            'change-process-properties': 'canChangeProcessProperties',
            'refund-invoices': 'canRefundInvoices',
            'make-immidiate-payment': 'canMakeImmidiatePayment'

        };

        const canPerform = false;
        const filteredSubscriptions: SubscriptionModel[] = [];
        const canActionProperty = canPeformAction[action];
        if (subscriptions && canActionProperty != null) {
            if (!(subscriptions instanceof Array)) {
                subscriptions = [subscriptions];
            }

            subscriptions.forEach(subscription => {
                if (!canPerform && subscription[canActionProperty]) {
                    filteredSubscriptions.push(subscription);
                }
            });
        }

        return filteredSubscriptions;
    }
}


export class SubscriptionTemplateModel extends BaseModel {
    id: number;
    name: string;
    description: string;
    subcriptionType: string;
    prefix: string;
    numbering: string;
    periodDuration: number;
    recurringInterval: number;
    minimumTerm: number;
    leadTime: number;
    leadTimeType: string;
    cancelPeriod: number;
    cancelPeriodType: string;
    cancellationNoticePeriod: number;
    cancellationNoticePeriodType: string;
    revocationPeriod: number;
    revocationPeriodType: string;
    renewalIntervals: number;
    billingFrequency: number;
    invoiceMethod: string;
    debitLimit: number;
    settlementMethod: string;
    standardDebitDate: Date;
    lastDebitDate: Date;
    paymentMethod: string;
    product: ProductModel;
    additionalProducts: ProductModel[];
    dependentTemplates: SubscriptionTemplateModel[];
    processProperties: ProcessProperty[];
    public static getInstanceFromResponse(response: any): SubscriptionTemplateModel {
        const model = new SubscriptionTemplateModel();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.description = BaseModel.getValue(response.description);
        model.subcriptionType = BaseModel.getValue(response.subcriptionType);
        model.prefix = BaseModel.getValue(response.prefix);
        model.numbering = BaseModel.getValue(response.numbering);
        model.periodDuration = BaseModel.getValue(response.periodDuration);
        model.recurringInterval = BaseModel.getValue(response.recurringInterval);
        model.minimumTerm = BaseModel.getValue(response.minimumTerm);
        model.leadTime = BaseModel.getValue(response.leadTime);
        model.leadTimeType = BaseModel.getValue(response.leadTimeType);
        model.cancelPeriod = BaseModel.getValue(response.cancelPeriod);
        model.cancelPeriodType = BaseModel.getValue(response.cancelPeriodType);
        model.cancellationNoticePeriod = BaseModel.getValue(response.cancellationNoticePeriod);
        model.cancellationNoticePeriodType = BaseModel.getValue(response.cancellationNoticePeriodType);
        model.revocationPeriod = BaseModel.getValue(response.revocationPeriod);
        model.revocationPeriodType = BaseModel.getValue(response.revocationPeriodType);
        model.renewalIntervals = BaseModel.getValue(response.renewalIntervals);
        model.billingFrequency = BaseModel.getValue(response.billingFrequency);
        model.invoiceMethod = BaseModel.getValue(response.invoiceMethod);
        model.debitLimit = BaseModel.getValue(response.debitLimit);
        model.settlementMethod = BaseModel.getValue(response.settlementMethod);
        model.standardDebitDate = BaseModel.getDate(response.standardDebitDate);
        model.lastDebitDate = BaseModel.getDate(response.lastDebitDate);
        model.paymentMethod = BaseModel.getValue(response.paymentMethod);
        model.product = ProductModel.getInstanceFromResponse(response.product);
        model.additionalProducts = model.product.additionalProducts;
        if (response.dependentTemplates) {
            model.dependentTemplates = SubscriptionTemplateModel.getInstancesFromResponse(response.dependentTemplates);
        }
        model.processProperties = ProcessProperty.getInstancesFromResponse(response.processProperties, model.product);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionTemplateModel[] {
        const models: SubscriptionTemplateModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: SubscriptionTemplateModel = SubscriptionTemplateModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export interface SubscriptionCancellationRequest {
    id: number;
    cancellationDate: Date;
    cancellationType: string;
    sendCommunication: boolean;
}

export interface GetSubscriptionCancellationDateRequest {
    id: number;
    cancellationType: string;
}

export interface GetSubscriptionReactivationDateRequest {
    id: number;
}

export interface SubscriptionReactivationRequest {
    id: number;
    reactivationDate: Date;
}

export interface SubscriptionMakeImmidiatePaymentRequest {
    subscriptionId: number;
    periodStart:any [];
    cancelChargeback:boolean;
    addBonus:boolean;
    bonusAmount: number;

}

export interface SubscriptionRevokeRequest {
    id: number;
}

export interface SubscriptionChangeTemplateRequest {
    id: number;
    templateId: number;
    productUomQty: number;
    retainAdditionalProducts: boolean;
    properties: any;
}

export interface SubscriptionApplyBonusRequest {
    id: number;
    creditType: string;
    amount: number;
}

export interface SubscriptionChangePaymentMethodRequest {
    id: number;
    bankId: number;
}

export interface SubscriptionChangeProcessPropertiesRequest {
    id: number;
    properties: any[];
}


export interface SubscriptionPayoutRequest {
    subscriptionId: number;
}

export interface SubscriptionRefundinvoicesRequest {
    subscriptionId: number;
}

export const MAIN_PRODUCT = 'PRODUCT';
export const PACK_PRODUCT = 'PACK_PRODUCT';
export const ADDITIONAL_PRODUCT = 'ADDITIONAL_PRODUCT';

export class SubscriptionItem extends BaseModel {
    id: number;
    sequence: number;
    type: string;
    showInInvoice: boolean;
    product: ProductModel;
    productUomQty: number;


    public static getInstanceFromResponse(response: any): SubscriptionItem {
        const model = new SubscriptionItem();
        model.id = BaseModel.getValue(response.id);
        model.sequence = BaseModel.getValue(response.sequence);
        model.type = BaseModel.getValue(response.type);
        model.showInInvoice = response.showInInvoice;
        model.product = ProductModel.getInstanceFromResponse(response.product);
        model.productUomQty = BaseModel.getValue(response.productUomQty);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionItem[] {
        const models: SubscriptionItem[] = [];
        if (responses) {
            responses.forEach(response => {
                if (response.type !== 'PRODUCT') {
                    const model: SubscriptionItem = SubscriptionItem.getInstanceFromResponse(response);
                    models.push(model);
                }
            });
        }
        return models;
    }

    public static isMainProduct(item: SubscriptionItem) {
        return item && item.type === MAIN_PRODUCT;
    }

    public static isPackProduct(item: SubscriptionItem) {
        return item && item.type === PACK_PRODUCT;
    }

    public static isAdditionalProduct(item: SubscriptionItem) {
        return item && item.type === ADDITIONAL_PRODUCT;
    }
}


export class AdditionalProduct extends BaseModel {
    id: number;
    sequence: number;
    product: ProductModel;

    public static getInstanceFromResponse(response: any): AdditionalProduct {
        const model = new AdditionalProduct();
        model.id = BaseModel.getValue(response.id);
        model.sequence = BaseModel.getValue(response.sequence);
        model.product = ProductModel.getInstanceFromResponse(response.product);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): AdditionalProduct[] {
        const models: AdditionalProduct[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: AdditionalProduct = AdditionalProduct.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }

}

export class ProcessProperty extends BaseModel {
    id: number;
    name: string;
    displayName: string;
    value: string;
    source: string;
    helpText: string;
    fieldType: string;
    processId: string;
    processName: string;
    processDisplayName: string;
    processDesc: string;
    required: boolean;
    pattern: string;
    multiValue: boolean;
    multiValueSize: number;
    multiValueDelimiter: string;

    // transistion
    product: ProductModel;

    public static getInstanceFromResponse(response: any, product: ProductModel): ProcessProperty {
        const model = new ProcessProperty();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.displayName = BaseModel.getValue(response.displayName);
        model.value = BaseModel.getValue(response.value);
        model.source = BaseModel.getValue(response.source);
        model.helpText = BaseModel.getValue(response.helpText);
        model.fieldType = BaseModel.getValue(response.fieldType);
        model.processId = BaseModel.getValue(response.processId);
        model.processName = BaseModel.getValue(response.processName);
        model.processDesc = BaseModel.getValue(response.processDesc);
        model.processDisplayName = BaseModel.getValue(response.processDisplayName);
        model.pattern = BaseModel.getValue(response.pattern);
        model.required = response.required;
        model.multiValue = response.multiValue;
        model.multiValueSize = BaseModel.getValue(response.multiValueSize);
        model.multiValueDelimiter = BaseModel.getValue(response.multiValueDelimiter);
        model.product = product;
        return model;
    }

    public static getInstancesFromResponse(responses: any[], product: ProductModel): ProcessProperty[] {
        const models: ProcessProperty[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: ProcessProperty = ProcessProperty.getInstanceFromResponse(response, product);
                models.push(model);
            });
        }
        return models;
    }
}

export class AdditionalProperty extends BaseModel {
    id: number;
    group: string;
    name: string;
    value: string;

    public static getInstanceFromResponse(response: any): AdditionalProperty {
        const model = new AdditionalProperty();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.group = BaseModel.getValue(response.group);
        model.value = BaseModel.getValue(response.value);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): AdditionalProperty[] {
        const models: AdditionalProperty[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: AdditionalProperty = AdditionalProperty.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}


export interface SubscriptionManageAddonsRequest {
    id: number;
    items: SubscriptionManageAddonsItem[];
}

export interface SubscriptionManageAddonsItem {
    productId: number;
    productUomQty: number;
    operation: string;
}
