import { Component, ComponentFactoryResolver } from '@angular/core';
import { UiBaseComponent } from '../ui-base.component';

@Component({
    selector: 'app-ui-label',
    templateUrl: 'ui-label.component.html',
    styleUrls: ['../ui-base.component.scss', 'ui-label.component.scss']
})
export class UiLabelComponent extends UiBaseComponent {

}
