export class TransactionModel {
    transactionId: string;
    transactionDate: Date;
    transactionType: string;
    subscriptionId: string;
    contactId: string;
    periodId: string;
    currency: string;
    amount: number;
    status: string;
    paymentMethod: string;
    paymentAccount: string;
    accountingType: string;
    accountingDate: Date;
    owner: string;
    createdBy: string;
    createdOn: Date;
    updatedBy: string;
    updatedOn: Date;


    public static getInstanceFromResponse(response: any): TransactionModel {
        const transaction: TransactionModel = new TransactionModel();
        transaction.transactionId = response.transactionId;
        if (response.transactionDate != null) {
            transaction.transactionDate = new Date(response.transactionDate);
        }
        transaction.transactionType = response.transactionType;
        transaction.transactionId = response.transactionId;
        transaction.subscriptionId = response.subscriptionId;
        transaction.contactId = response.contactId;
        transaction.periodId = response.periodId;
        transaction.currency = response.currency;
        transaction.amount =  response.amount;
        transaction.status = response.status;
        transaction.paymentMethod = response.paymentMethod;
        transaction.paymentAccount = response.paymentAccount;
        transaction.accountingType = response.accountingType;
        if (response.accountingDate != null) {
            transaction.accountingDate = new Date(response.accountingDate);
        }
        transaction.owner = response.owner;
        transaction.createdBy = response.createdBy;
        if (response.createdOn != null) {
            transaction.createdOn = new Date(response.createdOn);
        }
        transaction.updatedBy = response.updatedBy;
        if (response.updatedOn != null) {
            transaction.updatedOn = new Date(response.updatedOn);
        }
        return transaction;
    }

    public static getInstancesFromResponse(responses: any[]): TransactionModel[] {
        const transactions: TransactionModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const transaction: TransactionModel = TransactionModel.getInstanceFromResponse(response);
                transactions.push(transaction);
            });
        }
        return transactions;
    }

    public static clone(model: TransactionModel): TransactionModel {
        const cloned = new TransactionModel();
        cloned.transactionId = model.transactionId;
        cloned.transactionDate = model.transactionDate;
        cloned.transactionType = model.transactionType;
        cloned.subscriptionId = model.subscriptionId;
        cloned.contactId = model.contactId;
        cloned.periodId = model.periodId;
        cloned.currency = cloned.currency;
        cloned.amount = model.amount;
        cloned.status = model.status;
        cloned.paymentMethod = model.paymentMethod;
        cloned.paymentAccount = model.paymentAccount;
        cloned.accountingType = model.accountingType;
        if (cloned.accountingDate != null) {
            cloned.accountingDate = new Date(model.accountingDate.getTime());
         }
        cloned.owner = model.owner;
        cloned.createdBy = model.createdBy;
        if (cloned.createdOn != null) {
           cloned.createdOn = new Date(model.createdOn.getTime());
        }
        cloned.updatedBy = model.updatedBy;
        if (cloned.updatedOn != null) {
           cloned.updatedOn = new Date(model.updatedOn.getTime());
        }
    return cloned;
    }

}
