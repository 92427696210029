/**
 * Shared Module contains all the standard pipes, the module imports I18NModule should add TranslatorPipe in the Provider list
 */

// Angular
import { NgModule } from '@angular/core';
import { DecimalPipe, DatePipe, TitleCasePipe } from '@angular/common';

// 3rd Party

// Application Specific
import { SafePipe } from './safe.pipe';
import { UnitPipe } from './unit.pipe';
import { AddWhiteSpacePipe } from './add-white-space.pipe';
import { CapitalizeWordsPipe } from './capitalize-words.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TransactionFilter } from './transactions.filter';
import { ContactTypePipe } from './contact-type.pipe';
import { OrdersFilter } from './orders.filter';
import { ProductsFilter } from './products.filter';
import { InvoicesFilter } from './invoices.filter';
import { XmlPipe } from './xml-pipe';
import { AuditLogsFilter } from './auditlogs.filter';
import { ComplaintsFilter } from './complaints.filter';
import { PaymentsFilter } from './payments.filter';
import { CommunicationsFilter } from './communicattions.filter';
import { NotificationChannelPipe } from './notification-channel.pipe';
@NgModule({
    declarations: [
        SafePipe,
        TimeAgoPipe,
        UnitPipe,
        AddWhiteSpacePipe,
        CapitalizeWordsPipe,
        TransactionFilter,
        ContactTypePipe,
        OrdersFilter,
        ProductsFilter,
        InvoicesFilter,
        XmlPipe,
        AuditLogsFilter,
        ComplaintsFilter,
        PaymentsFilter,
        CommunicationsFilter,
        NotificationChannelPipe
    ],
    exports: [
        SafePipe,
        TimeAgoPipe,
        UnitPipe,
        AddWhiteSpacePipe,
        CapitalizeWordsPipe,
        TransactionFilter,
        ContactTypePipe,
        OrdersFilter,
        ProductsFilter,
        InvoicesFilter,
        XmlPipe,
        AuditLogsFilter,
        ComplaintsFilter,
        PaymentsFilter,
        CommunicationsFilter,
        NotificationChannelPipe
    ],
    providers: [
        DecimalPipe,
        DatePipe,
        TitleCasePipe
    ]
})
export class PipesModule {

}
