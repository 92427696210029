import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ComplaintModel } from '../models/complaint.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class ComplaintService extends OdooHttpBaseService {
    baseUrl = 'complaint';

    constructor(injector: Injector) {
        super(injector);
    }

    search(origin: string, criteria: any[]): Observable<ComplaintModel[]> {
        if (origin == 'I') {
            origin = 'internal';
        } else if  (origin == 'E') {
            origin = 'external';
        }  else if (origin == null) {
            origin = 'external';
        }
        const observable = new Observable<ComplaintModel[]>(subscriber => {
            this._post(`${this.baseUrl}/${origin}/search`, {criteria: criteria, page: 1}).subscribe(items => {
                if (items != null) {
                    const complaints = ComplaintModel.getInstancesFromResponse(items);
                    subscriber.next(complaints);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getPeriods(subscriptionId: number): Observable<Date[]>  {
        const request = {
            subscriptionId: subscriptionId
        }
        const observable = new Observable<Date[]>(subscriber => {
            this._post(`${this.baseUrl}/periods`, request).subscribe(periods => {
                subscriber.next(periods);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    save(complaint: ComplaintModel): Observable<any>  {
        origin = 'external';
        if (complaint.complaintOrigin == 'I') {
            origin = 'internal';
        } else if  (complaint.complaintOrigin == 'E') {
            origin = 'external';
        }  else if (complaint.complaintOrigin == null) {
            origin = 'external';
        }
        const observable = new Observable<Date[]>(subscriber => {
            this._post(`${this.baseUrl}/${origin}`, complaint).subscribe(response => {
                subscriber.next(response);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

}
