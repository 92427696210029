/**
 * App level moudle
 */

// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// 3rd party
import 'hammerjs';
import 'chartjs-plugin-zoom';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { appRxStompConfig } from './app-rx-stomp.config';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// Containers
import { DefaultLayoutComponent } from './containers';

// Appliction Specific
import { AppComponent } from './app.component';
import { P401Component } from './views/error/401.component';
import { P403Component } from './views/error/403.component';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { LoginService } from './views/login/login.service';
import { AuthInterceptor } from './providers/auth-error-interceptor';
import { TranslatorPipe } from './i18n/translator.pipe';
import { I18NModule } from './i18n/i18n.module';
import { HomeComponent } from './views/home.component';

// Routing module
import { AppRoutingModule } from './app.routing';
import { AppContext } from './app.context';
import { FormsModule } from '@angular/forms';
import { AutoLoginComponent } from './views/login/auto-login.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from './pipes/pipes.module';
import { UnitPipe } from './pipes/unit.pipe';
import { UiRendererModule } from './views/core/ui-renderer/ui-renderer.module';
import { DirectivesModule } from './directives/directives.module';
import { MenuService } from './views/login/menu.service';
import { TopMenuModule } from './views/core/top-menu/top-menu.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SelectDropDownModule } from './views/core/ngx-select-dropdown';
import { ContactService } from './providers/contact.service';
import { SubscriptionSerivce } from './providers/subscription.service';
import { TransactionSerivce } from './providers/transaction.service';
import localeDe from '@angular/common/locales/de';
import { CommunicationSerivce } from './providers/communication.service';
import { CountryService } from './providers/country.service';
import { ProductService } from './providers/product.service';
import { CampaignService } from './providers/campaign.service';
import { OrderService } from './providers/order.service';
import { BankService } from './providers/bank.service';
import { InvoiceService } from './providers/invoice.service';
import { PaymentService } from './providers/payment.service';
import { SubscriptionProcessService } from './providers/subscription-process.service';
import { AuditlogService } from './providers/auditlog.service';
import { TranslationService } from './providers/translation-service';
import { ProfileService } from './providers/profile.service';
import { ComplaintService } from './providers/complaint.service';

registerLocaleData(localeDe);
export const GLOBAL = {};
export function startupServiceFactory(service: TranslationService): Function {
  return () => {
    const promise = new Promise((resolve: any, reject: any) => {
      service.load().then(translations => {
        GLOBAL['translations'] = translations;
        let locale = window.navigator.language;

        let response: any = localStorage.getItem('LOGIN_RESPONSE');
        if (response) {
          response = JSON.parse(response);
          locale = response.user_context.lang;
        }

        locale = locale.substring(0, 2);
        if (translations[locale] == null) {
          locale = 'en';
        }
        GLOBAL['locale'] = locale;
        resolve(translations);
      });
    });

    return promise;
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    SelectDropDownModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    I18NModule,
    ModalModule.forRoot(),
    PipesModule,
    UiRendererModule,
    DirectivesModule,

    TopMenuModule,
    NgxMaterialTimepickerModule
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    P401Component,
    P403Component,
    P404Component,
    P500Component,
    LoginComponent,
    AutoLoginComponent,
    HomeComponent
    ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: InjectableRxStompConfig,
      useValue: appRxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },
    TranslationService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [TranslationService, AppContext],
      multi: true
    },
    AppContext,
    MenuService,
    LoginService,
    TranslatorPipe,
    DatePipe,
    UnitPipe,
    ContactService,
    SubscriptionSerivce,
    TransactionSerivce,
    CommunicationSerivce,
    CountryService,
    ProductService,
    CampaignService,
    OrderService,
    BankService,
    InvoiceService,
    PaymentService,
    SubscriptionProcessService,
    AuditlogService,
    ProfileService,
    ComplaintService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
