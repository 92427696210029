// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  //odooAppBaseUrl: 'http://ec2-3-66-72-162.eu-central-1.compute.amazonaws.com:5000/app',
  //odooApiBaseUrl: 'http://ec2-3-66-72-162.eu-central-1.compute.amazonaws.com:5000/api',
  
  // odooAppBaseUrl: 'http://ec2-35-157-163-136.eu-central-1.compute.amazonaws.com:5000/app',
  // odooApiBaseUrl: 'http://ec2-35-157-163-136.eu-central-1.compute.amazonaws.com:5000/api',

  odooAppBaseUrl: 'http://o2o_odoo.alvadoo-test.de/app',
  odooApiBaseUrl: 'http://o2o_odoo.alvadoo-test.de/app',

  //odooAppBaseUrl: 'https://o2o-prod.alvadoo.de/app',
  //odooApiBaseUrl: 'https://o2o-prod.alvadoo.de/app',

  // odooAppBaseUrl: 'http://localhost:5000/app',
  // odooApiBaseUrl: 'http://localhost:5000/app',
  // odooAppBaseUrl: 'http://192.168.1.11:5000/app',
  // odooApiBaseUrl: 'http://192.168.1.11:5000/api',
  websocketURL: 'wss://pps.eu//wn_notifier/ws-session/websocket',
  s3_folder: '',
  api_key: 'O2O-83b7bc0f-f341-414c-a814-244be55bb079'
};
