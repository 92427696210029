import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavData } from '../../../_nav';
import { AppContext } from '../../../app.context';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
    selector: 'app-top-menu',
    templateUrl: 'top-menu.component.html',
    styleUrls: ['top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
    // tslint:disable-next-line:no-input-rename
    @Input('menu-items') navItems: NavData[];

    routerEvents: any;

    constructor(private context: AppContext, private router: Router) {

    }

    ngOnInit() {
        this.routerEvents = this.router.events;
    }

    selectMenuItem(navItem: NavData) {
        this.context.selectedMenuItem = navItem;
    }

    onMenuClick(navItem: NavData) {
        this.context.selectedMenuItem = navItem;
        if (this.hasChildren()) {
            // this.toggleOpen($event);
        } else if (navItem.action) {
            this.context.notifyActionMenu(navItem);
        } else if (typeof navItem.function === 'function') {
            navItem.function(navItem);
        } else {
            if (window.innerWidth <= 768) {
                document.body.classList.remove('sidebar-show');
            }
        }
        // this.openUrl($event);
    }

    hasChildren(navItem?: NavData): boolean {
        if (navItem == null) {
            navItem = this.context.selectedMenuItem;
        }
        if (navItem && navItem.children && navItem.children.length > 1) {
            return true;
        } else {
            return false;
        }
    }

    hasIcon(navItem?: NavData): boolean {
        if (navItem && navItem.icon && navItem.icon.trim() !== '') {
            return true;
        } else {
            return false;
        }
    }

    getRoutedPage(): NavData {
        const currentModuleName = this.context.currentModuleName;
        let selectedNavItem = this.navItems[1];

        switch (currentModuleName) {
            case 'contacts' : {
                selectedNavItem = this.navItems.find(x => x.name === 'mnu_main_contacts');
                break;
            }
            case 'subscriptions' : {
                selectedNavItem = this.navItems.find(x => x.name === 'mnu_main_subscriptions');
                break;
            }
            default : {
                selectedNavItem = this.navItems.find(x => x.name === 'mnu_main_subscriptions');
                break;
            }
        }

        this.context.selectedMenuItem = selectedNavItem;

        return selectedNavItem;
    }
}
