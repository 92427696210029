/**
 * Base Search criteria model
 */
import { BaseModel } from './base.model';

export class BaseSearchCriteriaModel extends BaseModel {
    pageNumber: number = 1;
    pageSize: number = 1000;

    orderByColumn: string;
    orderBy: string;
}
