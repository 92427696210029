import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { InvoiceLineModel } from './invoice-line.model';
import { CurrencyModel } from './product.model';
import { UserModel } from './user.model';


export class InvoiceModel extends BaseModel {
    id: string;
    partnerId: string;
    dateInvoice: Date;
    number: string;
    name: string;
    type: string;
    reference: string;
    state: string;
    dateDue: Date;
    amountUntaxed: number;
    amountTax: number;
    amountTotal: number;
    invoiceAddress: AddressModel;
    deliveryAddress: AddressModel;
    user: UserModel;
    currency: CurrencyModel;
    invoiceLines: InvoiceLineModel[];
    subscription: InvoiceSubscription;
    residualSigned: number;
    amountTotalSigned: number;
    origin: string;
    refundInvoiceId: number;
    refundInvoiceNumber: string;
    refundInvoiceReference: string;
    periodStart: Date;
    productCode: string;
    inprocess: boolean;

    public static getInstanceFromResponse(response: any): InvoiceModel {
        const model: InvoiceModel = new InvoiceModel();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.type = BaseModel.getValue(response.type);
        model.number = BaseModel.getValue(response.number);
        model.reference = BaseModel.getValue(response.reference);
        model.partnerId = BaseModel.getValue(response.partnerId);
        if (model.number === '' || model.number == null) {
            model.number = model.reference;
        }
        model.dateInvoice = BaseModel.getDate(response.dateInvoice);
        model.state = BaseModel.getValue(response.state);
        model.dateDue = BaseModel.getDate(response.dateDue);
        model.amountUntaxed = BaseModel.getValue(response.amountUntaxed);
        model.amountTax = BaseModel.getValue(response.amountTax);
        model.amountTotal = BaseModel.getValue(response.amountTotal);
        model.residualSigned = BaseModel.getValue(response.residualSigned);
        if (model.residualSigned == null) {
            model.residualSigned = 0;
        }
        model.amountTotalSigned = BaseModel.getValue(response.amountTotalSigned);
        model.origin = BaseModel.getValue(response.origin);
        model.refundInvoiceId = BaseModel.getValue(response.refundInvoiceId);
        model.refundInvoiceNumber = BaseModel.getValue(response.refundInvoiceNumber);
        model.refundInvoiceReference = BaseModel.getValue(response.refundInvoiceReference);
        model.periodStart = BaseModel.getDate(response.periodStart);
        model.invoiceAddress = AddressModel.getInstanceFromResponse(response.invoiceAddress);
        model.deliveryAddress = AddressModel.getInstanceFromResponse(response.deliveryAddress);
        model.user = UserModel.getInstanceFromResponse(response.user);
        model.currency = CurrencyModel.getInstanceFromDepenentResponse(response);
        model.invoiceLines = InvoiceLineModel.getInstancesFromResponse(response.invoiceLines);
        model.inprocess = BaseModel.getValue(response.inprocess);
        if (model.state === 'open' && model.inprocess) {
            model.state = 'payment_sent';
        }
        if (model.invoiceLines.length > 0) {
            model.productCode = model.invoiceLines[0].product.defaultCode;
        }
        if (!model.periodStart) {
            if (model.type ===  'out_invoice') {
                const chargeback = model.invoiceLines.find(x => x.product.defaultCode === 'CBFEE') ;
                if (!chargeback) {
                    const invoiceDay = model.dateInvoice.getDate();
                    model.periodStart = new Date(model.dateInvoice.getTime());
                    if (invoiceDay >= 15) {
                        model.periodStart.setMonth(model.periodStart.getMonth() + 1);
                    }
                    model.periodStart.setDate(15);
                }
            } else {
                const lottwin = model.invoiceLines.find(x => x.product.defaultCode === 'LOTT-WIN');
                if (lottwin) {
                    const invoiceDay = model.dateInvoice.getDate();
                    model.periodStart = new Date(model.dateInvoice.getTime());
                    if (invoiceDay >= 15) {
                        model.periodStart.setMonth(model.periodStart.getMonth() - 1);
                    }

                    model.periodStart.setDate(15);
                }
            }
        }

        model.subscription = {} as InvoiceSubscription;
        if (response.subscription) {
            const subscription = response.subscription;
            model.subscription.id = BaseModel.getValue(subscription.id);
            model.subscription.name = BaseModel.getValue(subscription.name);
            model.subscription.referenceId = BaseModel.getValue(subscription.referenceId);
            if (subscription.subscriptionTemplate) {
                const subscriptionTemplate = subscription.subscriptionTemplate;
                subscription.subscriptionTemplate = {} as InvoiceSubscriptionTemplate;
                subscription.subscriptionTemplate.id = BaseModel.getValue(subscriptionTemplate.id);
                subscription.subscriptionTemplate.name = BaseModel.getValue(subscriptionTemplate.name);
                subscription.subscriptionTemplate.description = BaseModel.getValue(subscriptionTemplate.description);
            }
        }

        return model;
    }

    public static getInstancesFromResponse(responses: any): InvoiceModel[] {
        const models: InvoiceModel[] = [];
        if (responses) {
            responses.forEach((response, index) => {
                if (response.state !== 'draft') {
                    const model: InvoiceModel = InvoiceModel.getInstanceFromResponse(response);
                    models.push(model);
                }
            });
        }
        return models;
    }
}


export interface InvoiceSubscription {
    id: number;
    name: string;
    referenceId: string;
    subscriptionTemplate: InvoiceSubscriptionTemplate;
}

export interface InvoiceSubscriptionTemplate {
    id: number;
    name: string;
    description: string;
}
