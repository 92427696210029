import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryModel, CountryStateModel } from '../models/country.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

export class CountryService extends OdooHttpBaseService {
    baseUrl = 'country';

    constructor(injector: Injector) {
        super(injector);
    }

    getIdField(): string {
        return 'id';
    }

    getCountries(criteria?: any[]): Observable<CountryModel[]> {
        if (criteria == null) {
            criteria = [];
        }
        const observable = new Observable<CountryModel[]>(subscriber => {
            this._post(`${this.baseUrl}/search`, {criteria: criteria}).subscribe(items => {
                if (items != null) {
                    const countries = CountryModel.getInstancesFromResponse(items);
                    subscriber.next(countries);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getCountryStates(countryId: number): Observable<CountryStateModel[]> {
        const observable = new Observable<CountryStateModel[]>(subscriber => {
            this._post(`${this.baseUrl}/${countryId}/state`, {}).subscribe(items => {
                if (items != null) {
                    const countryStates = CountryStateModel.getInstancesFromResponse(items);
                    subscriber.next(countryStates);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

}
