import { BaseModel } from './base.model';

export class BankModel {
    id: number;
    bic: string;
    name: string;
    active: boolean;

    public static getInstanceFromResponse(response: any): BankModel {
        const model: BankModel = new BankModel();
        model.id = response.id;
        model.bic = BaseModel.getValue(response.bic);
        model.name = BaseModel.getValue(response.name);
        model.active = response.active;
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): BankModel[] {
        const models: BankModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const bank: BankModel = BankModel.getInstanceFromResponse(response);
                models.push(bank);
            });
        }
        return models;
    }
}
