import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from '../models/contact.model';
import { OrderOnboardModel } from '../models/order-onboard.model';
import { OrderModel } from '../models/order.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class OrderService extends OdooHttpBaseService {
    baseUrl = 'sale-order';

    constructor(injector: Injector) {
        super(injector);
    }

    save(order: OrderOnboardModel): Observable<OrderOnboardModel> {
        const observable = new Observable<OrderOnboardModel>(subscriber => {
            const products = [];
            const additionalProductsMap = {};
            const productProcessProperties = {};
            if (this.context.isNotEmpty(order.order.processProperties)) {
                order.order.processProperties.forEach(processProperty => {
                    if (productProcessProperties[`product-${processProperty.product.id}`] == null) {
                        productProcessProperties[`product-${processProperty.product.id}`] = [];
                    }
                    productProcessProperties[`product-${processProperty.product.id}`].push({
                        name: processProperty.name,
                        value: processProperty.value
                    });
                });
            }
            order.order.products.forEach(product => {
                const orderProduct: any = {
                    productId: product.productId,
                    productUomQty: product.productUomQty,
                    campaign: product.campaign,
                };
                const additionalProperties = productProcessProperties[`product-${product.productId}`];
                if (this.context.isNotEmpty(additionalProperties)) {
                    orderProduct.additionalProperties = additionalProperties;
                }
                if (product.isAdditionalProduct) {
                    if (additionalProductsMap[product.mainProductId] == null) {
                        additionalProductsMap[product.mainProductId] = [];
                    }
                    additionalProductsMap[product.mainProductId].push(orderProduct);
                } else {
                    products.push(orderProduct);
                }
            });
            products.forEach(product => {
                if (additionalProductsMap[product.productId]) {
                    product.additionalProducts = additionalProductsMap[product.productId];
                }
            });
            const request: any = {
                order: {
                    date: this.context.getDateAsString(order.order.date),
                    products: products,
                    paymentMethod: order.order.preferredPaymentMethod.paymentMethod,
                    agentId: order.order.agentId
                }
            };
            if (order.contact.id && !order.contact.changed) {
                request.contactId = {
                    id: order.contact.id
                };
            } else {
                request.contact = ContactModel.getRequest(order.contact);
            }
            this._post(`${this.baseUrl}`, request).subscribe(response => {
                order.order.id = response.id;
                order.order.name = response.name;
                order.order.date = response.dateOrder;
                order.contact.id = response.partnerId;
                subscriber.next(order);
            }, error => {
                subscriber.error(error);
            });
        });

        return observable;
    }

    search(criteria: any[]): Observable<OrderModel[]> {
        const observable = new Observable<OrderModel[]>(subscriber => {
            this._post(`${this.baseUrl}/search`, {criteria: criteria, page: 1}).subscribe(items => {
                if (items != null) {
                    const orders = OrderModel.getInstancesFromResponse(items);
                    subscriber.next(orders);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

}
