/**
 * Provides Login and Logout methods
 */

// Angular
import { Injector, Injectable } from '@angular/core';

// 3rd Party
import { Observable } from 'rxjs';

// Application Specific
import { OdooHttpBaseService } from '../../providers/odoo-http-base.service';
import { UserModel } from '../../models/user.model';
import { MenuService } from './menu.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoginService extends OdooHttpBaseService {

  protected baseUrl = `security`;
  protected customerBaseUrl = `customers`;
  protected dealerBaseUrl = `dealers`;
  protected userBaseUrl = `users`;

  customerExistsForUser = false;
  menuService: MenuService;
  constructor(injector: Injector) {
      super(injector);
      this.menuService = injector.get(MenuService);
  }

  getUrl(url: string): string {
    if (!url.startsWith('/')) {
        url = `/${url}`;
    }
    url = `${environment.odooAppBaseUrl}${url}`;
    return url;
}

  /**
   * Login Service
   * @param username
   * @param password
   */
  login(username: string, password: string): Observable<any> {
      const loginRequest = {
        login: username,
        password: password
      };
      const observable: Observable<any> = new Observable(subscriber => {
        this.context.removeToken();
          this._post(`${this.baseUrl}/login`, loginRequest).subscribe(response => {
            this.context.setLoginResponse(response);
            this._postLogin(subscriber, response);
          }, error => {
              subscriber.error(error);
          });
      });
      return observable;
  }

  autoLoginIfRememberMe(): Observable<any> {
    const observable: Observable<any> = new Observable(subscriber => {
      if (this.context.isRememberMeEnabled()) {
        this.autoLogin().subscribe(response => {
          subscriber.next(response);
        }, error => {
          subscriber.error(error);
        });
      } else {
        subscriber.error('Rememer Me not Enabled');
      }
    });
    return observable;
  }


  /**
   * Auto login
   */
  autoLogin(): Observable<any> {
    const observable: Observable<any> = new Observable(subscriber => {
      this._post(`${this.baseUrl}/get_login`, {}).subscribe(response => {
        this.context.setLoginResponse(response);
        this._postLogin(subscriber, response);
      }, error => {
          subscriber.error(error);
      });
    });
    return observable;
  }

  _postLogin(subscriber: any, response: any) {
    const rememberMeToken: string = response.session_id;
    //this.context.setToken(rememberMeToken);

    const user: UserModel = UserModel.getInstanceFromResponse(response);
    this.context.setLoggedInUser(user);
    this.initalizeUserContext(response).then(() => {
      subscriber.next(response);
    }).catch((error) => {
      subscriber.error(error);
    });
  }


  /**
   * Logout the User
   *
   * @param changePassword
   */
  logout(): Observable<any> {
    const observable = new Observable<any>(subscriber => {
      this._post(`${this.baseUrl}/logout`, null).subscribe(response => {
        this.context.setLoginResponse(null);
        this.context.disableRememberMe();
        subscriber.next(true);
      }, error => {
        this.context.setLoginResponse(null);
        this.context.disableRememberMe();
        subscriber.next(false);
      });
    });
    return observable;
    }

    private initalizeUserContext(response: any): Promise<UserModel> {
    const promise = new Promise<UserModel>((resolve, reject) => {
      this.menuService.getMenu(response).subscribe(navData => {
        this.context.setUserMenuItems(navData);
          resolve(this.context.getLoggedInUser());
        }, error => {
          reject(error);
        });
      });
    return promise;
  }
}
