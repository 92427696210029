import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UiTable, UiRow } from '../ui-renderer.interfaces';

@Component({
    selector: 'app-ui-row',
    templateUrl: 'ui-row-renderer.component.html',
    styleUrls: ['ui-row-renderer.component.scss']
})
export class UiRowRendererComponent implements OnInit, OnChanges {
    // tslint:disable-next-line:no-input-rename
    @Input('meta') meta: UiRow;
    // tslint:disable-next-line:no-input-rename
    @Input('data') data: any;

    styleClass: string;

    ngOnInit() {

    }

    ngOnChanges() {
        if (this.meta.styleClass) {
            this.styleClass = this.meta.styleClass;
        } else {
            if (this.meta && this.meta.cells) {
                let noOfCells = this.meta.cells.length;
                const totalColumns = 12;
                if (noOfCells > 3) {
                    noOfCells = 3;
                }
                this.styleClass = `col-sm 12 col-md-${totalColumns / noOfCells}`;
            } else {
                this.styleClass = `col-sm 12`;
            }
        }
    }
}
