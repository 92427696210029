import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppContext } from '../app.context';
import { OdooHttpBaseService } from './odoo-http-base.service';
// tslint:disable-next-line:max-line-length
import { NewProcessRequest, SubscriptionProcessDetailModel, SubscriptionProcessModel, SubscriptionTemplateModel, SubscriptionTemplatePeriodModel, SubscriptionTemplateProcessModel } from '../models/subscription-process.model';

@Injectable()
export class SubscriptionProcessService extends OdooHttpBaseService {

    protected baseUrl = `process`;

    constructor(injector: Injector) {
        super(injector);
        this.context = injector.get(AppContext);
    }

    submitProcess(request: NewProcessRequest): Observable<SubscriptionProcessModel> {
        const newRequest: any = {
            template_id: request.template_id,
            period_start_date: this.context.getDateAsString(request.period_start_date),
            process_id: request.process_id,
            process_stage_ids: request.process_stage_ids
        };
        const observable = new Observable<SubscriptionProcessModel>(subscriber => {
            this._post(`${this.baseUrl}`, newRequest).subscribe(response => {
                const process = SubscriptionProcessModel.getInstanceFromResponse(response);
                subscriber.next(process);
            }, error => {
                subscriber.error(error);
            });
        });

        return observable;
    }


    getProcesses(criteria: any[]): Observable<SubscriptionProcessModel[]> {
        const observable = new Observable<SubscriptionProcessModel[]>(subscriber => {
            this._post(`${this.baseUrl}/search`, {criteria: criteria, page: 1}).subscribe(items => {
                if (items != null) {
                    const processes = SubscriptionProcessModel.getInstancesFromResponse(items);
                    subscriber.next(processes);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getProcessDetails(id: number): Observable<SubscriptionProcessDetailModel[]> {
        const observable = new Observable<SubscriptionProcessDetailModel[]>(subscriber => {
            this._post(`${this.baseUrl}/${id}/detail`, {}).subscribe(items => {
                if (items != null) {
                    const processDetails = SubscriptionProcessDetailModel.getInstancesFromResponse(items);
                    subscriber.next(processDetails);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getTemplates(): Observable<SubscriptionTemplateModel[]> {
        const observable = new Observable<SubscriptionTemplateModel[]>(subscriber => {
            this._post(`subscription_template/search`, {criteria: [], page: 1}).subscribe(items => {
                if (items != null) {
                    const templates = SubscriptionTemplateModel.getInstancesFromResponse(items);
                    subscriber.next(templates);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getTemplatePeriods(templateId: number): Observable<SubscriptionTemplatePeriodModel[]> {
        const observable = new Observable<SubscriptionTemplatePeriodModel[]>(subscriber => {
            this._post(`subscription_template/${templateId}/period`, {}).subscribe(items => {
                if (items != null) {
                    const periods = SubscriptionTemplatePeriodModel.getInstancesFromResponse(items);
                    subscriber.next(periods);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getTemplateProcesses(templateId: number): Observable<SubscriptionTemplateProcessModel[]> {
        const observable = new Observable<SubscriptionTemplateProcessModel[]>(subscriber => {
            this._post(`subscription_template/${templateId}/process`, {}).subscribe(items => {
                if (items != null) {
                    const processes = SubscriptionTemplateProcessModel.getInstancesFromResponse(items);
                    subscriber.next(processes);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

}
