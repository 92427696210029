import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UiTable } from '../ui-renderer.interfaces';

@Component({
    selector: 'app-ui-table',
    templateUrl: 'ui-table-renderer.component.html',
    styleUrls: ['ui-table-renderer.component.scss']
})
export class UiTableRendererComponent implements OnInit, OnChanges {
    // tslint:disable-next-line:no-input-rename
    @Input('meta') meta: UiTable;
    // tslint:disable-next-line:no-input-rename
    @Input('data') data: any;

    ngOnInit() {

    }

    ngOnChanges() {

    }

}
