interface ErrorMessages {
  [propName: string]: any;
}


export const errorMessages: ErrorMessages = {

} as ErrorMessages;






