/**
 * Decides the home page based on the user type
 */

// Angular
import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';

// Application Specific
import { AppContext } from '../app.context';

@Component({
    selector: 'app-home',
    template: ''
})
export class HomeComponent implements OnInit {

    constructor(
        private context: AppContext,
        private router: Router) {
    }

    ngOnInit(): void {
        const user = this.context.getLoggedInUser();
        if (user) {
            this.router.navigate(['/features/contacts']);
        } else {
            this.router.navigate(['auto-login']);
        }
    }
}
