import { Pipe, PipeTransform } from '@angular/core';
import { ComplaintModel } from '../models/complaint.model';
import { SubscriptionModel } from '../models/subscription.model';

@Pipe({
    name: 'complaints'
})
export class ComplaintsFilter  implements PipeTransform {
    transform(complaints: ComplaintModel[], subscriptions: SubscriptionModel[]) {
        const filtered: ComplaintModel[] = [];
        if (complaints != null && complaints.length > 0) {
            complaints.forEach(complaint => {
                if (subscriptions && subscriptions.length > 0) {
                    for (let s = 0; s < subscriptions.length; s++) {
                        const subscription = subscriptions[s];
                        if (complaint.subscriptionId && subscription.id === complaint.subscriptionId) {
                            filtered.push(complaint);
                            break;
                        }
                    }
                } else {
                    filtered.push(complaint);
                }
            });
        }
        return filtered;
    }
}
