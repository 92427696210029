import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { CommunicationModel, CommunicationNewprintRequest, CommunicationReprintRequest } from '../models/communication.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class CommunicationSerivce  extends OdooHttpBaseService {
    baseUrl = 'communications';
    constructor(injector: Injector) {
        super(injector);
    }

    get(partnerId: number): Observable<CommunicationModel[]> {
        const observable = new Observable<CommunicationModel[]>(subscriber => {
            this._post(`${this.baseUrl}`, {partnerId: partnerId}).subscribe(items => {
                if (items != null) {
                    const communications = CommunicationModel.getInstancesFromResponse(items);
                    subscriber.next(communications);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    reprint(request: CommunicationReprintRequest): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/reprint`, request).subscribe(items => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    newprint(request: CommunicationNewprintRequest): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/newprint`, request).subscribe(items => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }
}
