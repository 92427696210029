import { BaseModel } from './base.model';
import { CurrencyModel } from './product.model';

export class PaymentModel extends BaseModel {
    id: number;
    companyId: number;
    partnerId: number;
    partnerName: string;
    date: Date;
    name: string;
    ref: string;
    amount: number;
    currency: CurrencyModel;
    state: string;
    journalId: number;
    journalName: string;
    debit: number;
    credit: number;

    public static getInstanceFromResponse(response: any): PaymentModel {
        const model: PaymentModel = new PaymentModel();
        model.id = BaseModel.getValue(response.id);
        model.companyId = BaseModel.getValue(response.companyId);
        model.partnerId = BaseModel.getValue(response.partnerId);
        model.partnerName = BaseModel.getValue(response.partnerName);
        model.journalId = BaseModel.getValue(response.journalId);
        model.journalName = BaseModel.getValue(response.journalName);
        model.name = BaseModel.getValue(response.name);
        model.ref = BaseModel.getValue(response.ref);
        model.amount = BaseModel.getValue(response.amount);
        model.date = BaseModel.getDate(response.date);
        model.state = BaseModel.getValue(response.state);
        model.currency = CurrencyModel.getInstanceFromDepenentResponse(response);
        if (model.journalId === 1) {
            model.debit = model.amount;
        } else {
            model.credit = model.amount;
        }
        return model;
    }

    public static getInstancesFromResponse(responses: any): PaymentModel[] {
        const models: PaymentModel[] = [];
        if (responses) {
            responses.forEach((response, index) => {
                const model: PaymentModel = PaymentModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

