import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpXsrfTokenExtractor
   } from '@angular/common/http';
   import { Observable, throwError, of } from 'rxjs';
   import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private tokenExtractor: HttpXsrfTokenExtractor) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req = req.clone({ withCredentials: true});

        // return XSRF-TOKEN in each request's header (anti-CSRF security)
        // const headerName = 'X-XSRF-TOKEN';
        // const token = this.tokenExtractor.getToken() as string;
        // if (token !== null && !req.headers.has(headerName)) {
        //     req = req.clone({ headers: req.headers.set(headerName, token) });
        // }
        return next.handle(req).pipe(
            catchError( (error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401 || error.status === 403 || error.status === 404) {
                        this.router.navigate([`${error.status}`]);
                    }
                }
               return throwError(error);
            })
        );
    }
}
