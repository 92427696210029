/**
 * Allows user to login to the applciation
 */

// Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Application Specific
import { AppContext } from '../../app.context';
import { GLOBAL } from '../../app.module';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

  user: any = {};
  error: string;
  info: string;
  success: string;
  submitted = false;

  constructor(
    private service: LoginService,
    private context: AppContext,
    private router: Router,
    private route: ActivatedRoute
  ) {
    route.data.subscribe(data => {
      this.error = data.error;
    });
  }

  ngOnInit() {
    /*this.submitted = true;
    this.service.autoLogin().subscribe(response => {
      this.info = 'Please wait while logging in';
      this.user.name = response.username;
      this.performPostLogin(response);
    }, error => {
      this.submitted = false;
      this.info = null;
    });*/
  }

  login($event: any) {
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    setTimeout(() => {
      this.performLogin();
    });
  }

  performLogin() {
    this.error = null;
    this.context.clearContext();
    this.info = 'msg_login_info';
    this.service.login(this.user.name, this.user.password).subscribe(response => {
      this.performPostLogin(response);
    }, error => {
      // Authentication failed
      this.info = null;
      this.submitted = false;
      this.context.clearContext();
      this.error = 'msg_login_error';
    });
  }

  performPostLogin(response: any) {
    this.info = null;
    if (response != null && response.session_id != null) {
      let locale = response.user_context.lang;
      const translations = GLOBAL['translations'];
      locale = locale.substring(0, 2);
      if (translations[locale] == null) {
        locale = 'en';
      }
      GLOBAL['locale'] = locale;

      // Authenticated success
      this.success = 'msg_login_success';
      this.router.navigate(['home']);
    } else {
      // Authentication failed
      this.submitted = false;
      this.context.clearContext();
      this.error = 'msg_login_error';
    }
  }
}
