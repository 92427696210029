/**
 * Translation constants. the key should match with i18n languge code
 *
 * You can also add the localte e.g. en-US
 *
 * TODO: Only english is properly added with all the translations.
 * others are example only has to be added later
 */

import {Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { OdooHttpBaseService } from '../providers/odoo-http-base.service';

// Application Specific

@Injectable()
export class TranslationService  extends OdooHttpBaseService {
    baseUrl = 'translation';
    language: string;
    locale: string;
    translations = null;
    constructor(
        injector: Injector
        ) {
            super(injector);
            const locale = window.navigator.language;
            const localeSplit = locale.split("-");
            if (localeSplit.length === 2) {
                this.language = localeSplit[0];
            } else {
                this.language = locale;
            }
    }

    load(): Promise<any> {
        return this.popuplate();
    }
    
    popuplate() {
        const criteria = {
            
        }
        const _this = this;
        const promise = new Promise<any>((resolve, reject) => {
            this._post(`${this.baseUrl}/search`, {criteria: criteria}).subscribe(items => {
                _this.translations = {}
                if (items != null) {
                    if (_this.translations[this.language] == null) {
                        _this.translations[this.language] = {}
                        items.forEach(item => {
                            let translation = _this.translations[item.language];
                            if (translation == null) {
                                translation = {};
                                _this.translations[item.language] = translation;
                            }
                            translation[item.messageId] = item.message;
                        });
                        resolve(_this.translations)
                    }
                }
            }, error => {
                reject(error);
            })

        });
        return promise; 
    }

    getUrl(url: string): string {
        if (!url.startsWith('/')) {
            url = `/${url}`;
        }
        url = `${environment.odooApiBaseUrl}${url}`;
        return url;
    }
};
