import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notificationChannel'
})

export class NotificationChannelPipe implements PipeTransform {

    transform (value: any[], contactemail: any) {

         if (value) {
             if (!contactemail || contactemail === '') {
                 return [value.find(x => x.value === 'LETTER')];
                } else {
                 return value;
                }
        }
    }
}


