import { BaseSearchCriteriaModel } from './base-search-criteria.model';

export class TransactionSearchCriteriaModel extends BaseSearchCriteriaModel {
    contactId: number;
    subscriptionId: string;
    periodId: string;
    transactionType: string;
    status: string;
    createdBy: string;
    createdOn: string;
}
