/**
 * Shared Module, the module imports NoDblClickDirectiveModule should add NoDblClickDirective in the Provider list
 */

// Angular
import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';

// Application Specific
import { NoDblClickDirective } from './no-dbl-click.directive';

@NgModule({
    declarations: [
        NoDblClickDirective,
        ClickOutsideDirective
    ],
    exports: [
        NoDblClickDirective,
        ClickOutsideDirective
    ]
})
export class DirectivesModule {

}
