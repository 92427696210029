import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
    GetSubscriptionCancellationDateRequest,
    GetSubscriptionReactivationDateRequest,
    SubscriptionApplyBonusRequest,
    SubscriptionCancellationRequest,
    SubscriptionChangePaymentMethodRequest,
    SubscriptionChangeProcessPropertiesRequest,
    SubscriptionChangeTemplateRequest,
    SubscriptionMakeImmidiatePaymentRequest,
    SubscriptionManageAddonsRequest,
    SubscriptionModel,
    SubscriptionPayoutRequest,
    SubscriptionReactivationRequest,
    SubscriptionRevokeRequest,
    SubscriptionTemplateModel
} from '../models/subscription.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class SubscriptionSerivce extends OdooHttpBaseService {
    baseUrl = 'subscription';
    constructor(injector: Injector) {
        super(injector);
    }

    get(subscriptionId: string): Observable<SubscriptionModel> {
        const observable = new Observable<SubscriptionModel>(subscriber => {
            this._post(`${this.baseUrl}/${subscriptionId}/get`, {}).subscribe(item => {
                if (item != null) {
                    const subcription = SubscriptionModel.getInstanceFromResponse(item);
                    subscriber.next(subcription);
                } else {
                    subscriber.error(`Subscription with Id ${subscriptionId} does not exists`);
                }
            });
        });
        return observable;
    }

    getTemplate(subscriptionTemplateId: number): Observable<SubscriptionTemplateModel> {
        const observable = new Observable<SubscriptionTemplateModel>(subscriber => {
            this._post(`${this.baseUrl}_template/${subscriptionTemplateId}/get`, {}).subscribe(item => {
                if (item != null) {
                    const subcriptionTemplate = SubscriptionTemplateModel.getInstanceFromResponse(item);
                    subscriber.next(subcriptionTemplate);
                } else {
                    subscriber.error(`Subscription Template with Id ${subscriptionTemplateId} does not exists`);
                }
            });
        });
        return observable;
    }
    search(criteria: any[]): Observable<SubscriptionModel[]> {
        const searchCriteria = [];
        if (criteria) {
            criteria.forEach(condition => {
                if (condition.field === 'status') {
                    if (condition.value === 'ACTIVE') {
                        searchCriteria.push({
                            field: 'end_date',
                            operator: '=',
                            value: false
                        });
                    } else if (condition.value === 'CANCELLED') {
                        searchCriteria.push({
                            field: 'cancellation_status',
                            operator: '=',
                            value: 'CANCELLED'
                        });
                    }
                } else {
                    searchCriteria.push(condition);
                }
            });
        }
        const observable = new Observable<SubscriptionModel[]>(subscriber => {
            this._post(`${this.baseUrl}/search`, {criteria: searchCriteria, page: 1}).subscribe(responses => {
                if (responses) {
                    const subscriptions = SubscriptionModel.getInstancesFromResponse(responses);
                    this._getImmidiatePaymentPeriod(subscriber, subscriptions);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    _getImmidiatePaymentPeriod(subscriber: any, subscriptions: SubscriptionModel[], index = 0) {
        if (index >= subscriptions.length) {
            subscriber.next(subscriptions);
        } else {
            const subscription = subscriptions[index];
            this._post(`${this.baseUrl}/get_next_period_for_immidiate_payment`, {subscriptionId: subscription.id}).subscribe(response => {
                if (response) {
                    subscription.immidiatePaymentDate = response;
                    subscription.canMakeImmidiatePayment = true;
                } else {
                    subscription.canMakeImmidiatePayment = false;
                }
                this._getImmidiatePaymentPeriod(subscriber, subscriptions, index+1);

            })
        }
    }

    getByPartnerId(partnerId: number): Observable<SubscriptionModel[]> {
        const criteria = [];
        criteria.push({
            field: 'partner_id.id',
            operator: '=',
            value: partnerId
        });
        return this.search(criteria);
    }

    getCancellationDate(request: GetSubscriptionCancellationDateRequest): Observable<Date[]> {
        const observable = new Observable<Date[]>(subscriber => {
            this._post(`${this.baseUrl}/cancel_dates`, request).subscribe((responses: any[]) => {
                const cancellationDates: Date[] = [];
                if (responses) {
                    responses.forEach(response => {
                        if (this.context.isNotEmpty(response)) {
                            cancellationDates.push(new Date(response));
                        }
                    });
                }
                subscriber.next(cancellationDates);
            }, error  => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    cancel(request: SubscriptionCancellationRequest): Observable<boolean> {
        const cancelRequest = {
            id: request.id,
            cancellationDate: this.context.getDateAsString(request.cancellationDate),
            cancellationType: request.cancellationType,
            sendCommunication: request.sendCommunication
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/cancel`, cancelRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getReactivationDate(request: GetSubscriptionReactivationDateRequest): Observable<Date[]> {
        const observable = new Observable<Date[]>(subscriber => {
            this._post(`${this.baseUrl}/reactivate_dates`, request).subscribe((responses: any[]) => {
                const reactivationDates: Date[] = [];
                if (responses) {
                    responses.forEach(response => {
                        if (this.context.isNotEmpty(response)) {
                            reactivationDates.push(new Date(response));
                        }
                    });
                }
                subscriber.next(reactivationDates);
            }, error  => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    reactivate(request: SubscriptionReactivationRequest): Observable<boolean> {
        const reactivatecancelRequest = {
            id: request.id,
            reactivationDate: request.reactivationDate
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/reactivate`, reactivatecancelRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    revoke(request: SubscriptionRevokeRequest): Observable<boolean> {
        const revokeRequest = {
            id: request.id
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/revoke`, revokeRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    changeTemplate(request: SubscriptionChangeTemplateRequest): Observable<boolean> {
        const changeTemplateRequest = {
            id: request.id,
            templateId: request.templateId,
            productUomQty: request.productUomQty,
            retainAdditionalProducts: request.retainAdditionalProducts,
            properties: request.properties
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/change_template`, changeTemplateRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getTemplates(query: string): Observable<SubscriptionTemplateModel[]> {
        const request = {
            query: query
        };
        const observable = new Observable<SubscriptionTemplateModel[]>(subscriber => {
            this._post(`subscription_template/qsearch`, request).subscribe((responses) => {
                const templates = SubscriptionTemplateModel.getInstancesFromResponse(responses);
                subscriber.next(templates);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getSwitchTemplates(id: number): Observable<SubscriptionTemplateModel[]> {
        const request = {};
        const observable = new Observable<SubscriptionTemplateModel[]>(subscriber => {
            this._post(`subscription/${id}/get_switch_products`, request).subscribe((responses) => {
                const templates = SubscriptionTemplateModel.getInstancesFromResponse(responses);
                subscriber.next(templates);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    canInitiateAction(subscription: SubscriptionModel, action: string): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            const canPerform = SubscriptionModel.canPerformAction(subscription, action);
            subscriber.next(canPerform);
        });
        return observable;
    }

    canCancel(subscription: SubscriptionModel): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            if (subscription.statuses && subscription.statuses.indexOf('CANCELLED') >= 0) {
                subscriber.error('Subscription cannot be cancelled as it is already been cancelled');
            } else if (subscription.endDate != null) {
                subscriber.error('Subscription cannot be cancelled as it is already ended');
            } else {
                subscriber.next(true);
            }
        });
        return observable;
    }

    canReactivate(subscription: SubscriptionModel): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            if (subscription.statuses && subscription.statuses.indexOf('CANCELLED') === -1) {
                subscriber.error('It is not a cancelled Subscription to reactivate');
            } else if (subscription.statuses && subscription.statuses.indexOf('REACTIVATED') >= 0) {
                subscriber.error('Subscription already been reactivated');
            } else {
                subscriber.next(true);
            }
        });
        return observable;
    }

    canChangeProduct(subscription: SubscriptionModel): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            if (subscription.statuses && subscription.statuses.indexOf('CANCELLED') >= 0) {
                subscriber.error('Cannot switch product as Subscription is cancelled');
            } else if (subscription.endDate != null) {
                subscriber.error('Cannot switch product as Subscription is already ended');
            } else {
                subscriber.next(true);
            }
        });
        return observable;
    }

    manageAddons(request: SubscriptionManageAddonsRequest): Observable<boolean> {
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/item`, request).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    applyBonus(request: SubscriptionApplyBonusRequest): Observable<boolean> {
        const applyBonusRequest = {
            id: request.id,
            creditType: request.creditType,
            amount: request.amount
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/credit/add`, applyBonusRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    changePaymentMethod(request: SubscriptionChangePaymentMethodRequest): Observable<boolean> {
        const changePaymentMethodRequest = {
            id: request.id,
            bankId: request.bankId
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/change_payment_method`, changePaymentMethodRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    changeProcessProperties(request: SubscriptionChangeProcessPropertiesRequest): Observable<boolean> {
        const changeProcessPropertiesRequest = {
            id: request.id,
            properties: request.properties
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/change_process_properies`, changeProcessPropertiesRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    payout(request: SubscriptionPayoutRequest): Observable<boolean> {
        const payoutRequest = {
            subscriptionId: request.subscriptionId
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/payout`, payoutRequest).subscribe(() => {
                subscriber.next(true);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getFinalPayoutAmount(id: number): Observable<number> {
        const observable = new Observable<number>(subscriber => {
            this._post(`${this.baseUrl}/${id}/get_final_payout_amount`, {}).subscribe((amount) => {
                subscriber.next(amount);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    makeImmidiatePayment(request: SubscriptionMakeImmidiatePaymentRequest): Observable<boolean> {
        const makeImmidiatePaymentRequest = {
            subscriptionId: request.subscriptionId,
            periodStart: request.periodStart,
            cancelChargeback: request.cancelChargeback,
            addBonus:request.addBonus,
            bonusAmount: request.bonusAmount
        };
        const observable = new Observable<boolean>(subscriber => {
            this._post(`${this.baseUrl}/make_immidiate_payment`, makeImmidiatePaymentRequest).subscribe((result) => {
                subscriber.next(result);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }
}
