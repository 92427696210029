/**
 * It is the user service to change the password
 */

// Angular
import { Injectable, Injector } from '@angular/core';

// 3rd Party
import { Observable } from 'rxjs';

// Application Specific
import { ChangePasswordModel } from '../models/change-password.model';
import { ProfileModel } from '../models/profile.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class ProfileService extends OdooHttpBaseService {
    protected baseUrl = `security`;


    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Changes the password of the logged in user
     *
     * @param changePassword
     */
    changePassword(changePassword: ChangePasswordModel): Observable<any> {
        const changePasswordRequest = {
            username: this.context.getLoggedInUser().username,
            oldPassword: changePassword.oldPassword,
            newPassword: changePassword.newPassword
        };
        return this._post(`${this.baseUrl}/change-password`, changePasswordRequest);
     }

    /**
     * Updates the profile
     *
     * @param profile
     */
    updateProfile(profile: ProfileModel): Observable<any> {
        return this._post(`${this.baseUrl}/profile`, profile);
    }

}
