import { BaseModel } from './base.model';
import { InvoiceModel } from './invoice.model';
import { CurrencyModel } from './product.model';

export class PaymentRegistryModel extends BaseModel {

    id: number;
    invoiceNumbers: string;
    name: string;
    referenceId: string;
    companyId: number;
    partnerId: number;
    partnerName: string;
    paymentRegistrationDate: Date;
    status: string;
    paymentMethod: string;
    amount: number;
    currency: CurrencyModel;
    accountHolder: string;
    paymentDate: Date;
    debit: number;
    credit: number;
    direction: string;
    invoices: InvoiceModel[];
    periodStart: Date;
    subscription: PaymentSubscription;

    public static getInstanceFromResponse(response: any, chargeback = false): PaymentRegistryModel {
        const model: PaymentRegistryModel = new PaymentRegistryModel();
        model.id = BaseModel.getValue(response.id);
        model.referenceId = BaseModel.getValue(response.referenceId);
        model.companyId = BaseModel.getValue(response.companyId);
        model.partnerId = BaseModel.getValue(response.partnerId);
        model.partnerName = BaseModel.getValue(response.partnerName);
        model.direction = BaseModel.getValue(response.direction);
        model.periodStart = BaseModel.getDate(response.periodStart);
        model.subscription = {} as PaymentSubscription;
        model.subscription.id = BaseModel.getValue(response.subscripionId);
        model.subscription.referenceId = BaseModel.getValue(response.subscripionReferenceId);

        let direction = model.direction;
        if (chargeback) {
            model.paymentRegistrationDate = BaseModel.getDate(response.chargeBackDate);
            model.status = BaseModel.getValue(response.status);
            direction = model.direction == 'in_payment' ? 'out_payment' : 'in_payment'
        } else {
            model.paymentRegistrationDate = BaseModel.getDate(response.paymentRegistrationDate);
            if (response.status == 'charge_backed') {
                model.status = 'transmission_confirmed';
            } else {
                model.status = BaseModel.getValue(response.status);
            }
        }

        model.paymentMethod = BaseModel.getValue(response.paymentMethod);
        model.currency = CurrencyModel.getInstanceFromDepenentResponse(response);
        model.accountHolder = BaseModel.getValue(response.accountHolder);
        model.paymentDate = BaseModel.getDate(response.paymentDate);
        model.amount = BaseModel.getValue(response.amount);

        if (direction == 'in_payment') {
            model.debit = model.amount;
            model.amount = model.amount * -1
        } else {
            model.credit = model.amount;
        }

        model.invoices = InvoiceModel.getInstancesFromResponse(response.invoices);
        let invoiceNumbers = '';
        model.invoices.forEach(invoice => {
            if (invoiceNumbers != '') {
                invoiceNumbers = invoiceNumbers + ". ";
            }
            invoiceNumbers = invoiceNumbers + invoice.reference;
        });
        model.invoiceNumbers = invoiceNumbers;

        return model;
    }

    public static getInstancesFromResponse(responses: any): PaymentRegistryModel[] {
        const models: PaymentRegistryModel[] = [];
        if (responses) {
            responses.forEach((response, index) => {
                let model: PaymentRegistryModel;
                if (response.status == 'charge_backed') {
                    model = PaymentRegistryModel.getInstanceFromResponse(response, true);
                    models.push(model);
                }

                model = PaymentRegistryModel.getInstanceFromResponse(response);
                models.push(model);

            });
        }
        return models;
    }
}

export interface PaymentSubscription {
    id: number;
    referenceId: string;
}