import { UserModel } from '../../models/user.model';
import { Observable } from 'rxjs';
import { NavData } from '../../_nav';
import { Injectable, Injector } from '@angular/core';
import { OdooHttpBaseService } from '../../providers/odoo-http-base.service';

@Injectable()
export class MenuService extends OdooHttpBaseService {

    menu: { [key: string]: Observable<NavData[]> };


    constructor(injector: Injector) {
        super(injector);
        this.menu = {};
        this.menu['default'] = this.getMenuItems();
    }

    getMenu(user: UserModel): Observable<NavData[]> {
        const observable = new Observable<NavData[]>(subscriber => {
            const menu = this.menu['default'];
            menu.subscribe(navData => {
                subscriber.next(navData);
            });
        });
        return observable;
    }

    private getMenuItems(): Observable<NavData[]> {
        const context = this.context;
        const observable = new Observable<NavData[]>(subscriber => {
            const navItems: NavData[] = [
                {
                    name: 'mnu_main_contacts',
                    url: '/features/contacts'
                },
                {
                    name: 'mnu_main_subscriptions',
                    url: '/features/subscriptions'
                }
            ];
            return subscriber.next(navItems);
        });
        return observable;
    }
}
