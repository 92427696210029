import { BaseModel } from './base.model';

export class InvoiceLineModel extends BaseModel {
    id: number;
    invoiceId: number;
    name: string;
    product: InvoiceProduct;
    priceUnit: number;
    discount: number;
    priceSubtotal: number;
    priceTax: number;
    priceTotal: number;
    productUom: InvoiceUom;
    quantity: number;
    sequence: number;

    public static getInstanceFromResponse(response: any): InvoiceLineModel {
        const model: InvoiceLineModel = new InvoiceLineModel();
        model.id = BaseModel.getValue(response.id);
        model.invoiceId = BaseModel.getValue(response.invoiceId);
        model.name = BaseModel.getValue(response.name);
        if (response.product) {
            model.product = {
                id: BaseModel.getValue(response.product.id),
                defaultCode: BaseModel.getValue(response.product.defaultCode),
                name: BaseModel.getValue(response.product.name),
                pricesExtra: BaseModel.getValue(response.product.pricesExtra),
                lstPrice: BaseModel.getValue(response.product.lstPrice),
                priceExtra: BaseModel.getValue(response.product.priceExtra)
            } as InvoiceProduct;
        }
        model.priceUnit = BaseModel.getValue(response.priceUnit);
        model.discount = BaseModel.getValue(response.discount);
        model.priceSubtotal = BaseModel.getValue(response.priceSubtotal);
        model.priceTax = BaseModel.getValue(response.priceTax);
        model.priceTotal = BaseModel.getValue(response.priceTotal);
        if (response.productUom) {
            model.productUom = {
                id: BaseModel.getValue(response.productUom.id),
                name: BaseModel.getValue(response.productUom.name)
            } as InvoiceUom;
        }
        model.quantity = BaseModel.getValue(response.quantity);
        model.sequence = BaseModel.getValue(response.sequence);
        return model;
    }

    public static getInstancesFromResponse(responses: any): InvoiceLineModel[] {
        const models: InvoiceLineModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: InvoiceLineModel = InvoiceLineModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}


export interface InvoiceProduct {
    id: number;
    defaultCode: string;
    name: string;
    pricesExtra: string;
    lstPrice: number;
    priceExtra: number;
}

export interface InvoiceUom {
    id: number;
    name: string;
}
