import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BankModel } from '../models/bank.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

export class BankService extends OdooHttpBaseService {
    baseUrl = 'bank';

    constructor(injector: Injector) {
        super(injector);
    }

    quickSearch(query: string): Observable<BankModel[]> {
        const observable = new Observable<BankModel[]>(subscriber => {
            this._post(`${this.baseUrl}/qsearch`, {query: query}).subscribe(items => {
                if (items != null) {
                    const banks = BankModel.getInstancesFromResponse(items);
                    subscriber.next(banks);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }
}
