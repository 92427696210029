/**
 * User
 */
// Applicaion Specific
import { BaseModel } from './base.model';

export class UserModel extends BaseModel {
    id: number;
    name: string;
    displayName: string;
    username: string;
    partnerDisplayName: string;
    companyId: number;
    partnerId: number;

    public static getInstanceFromResponse(response: any): UserModel {
        const user: UserModel = new UserModel();
        user.id = response.uid;
        user.name = response.name;
        user.displayName = response.name;
        user.partnerDisplayName = response.partner_display_name;
        user.companyId = response.company_id;
        user.partnerId = response.partner_id;
        user.username = response.username;
        return user;
    }

    public static getInstancesFromResponse(responses: any[]): UserModel[] {
        const users: UserModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const user: UserModel = UserModel.getInstanceFromResponse(response);
                users.push(user);
            });
        }
        return users;
    }

}
