import { BaseModel } from './base.model';

export class OrderLineModel extends BaseModel {
    id: number;
    orderId: number;
    name: string;
    product: OrderProduct;
    priceUnit: number;
    priceReduce: number;
    priceSubtotal: number;
    priceTax: number;
    priceTotal: number;
    productUom: OrderUom;
    productUomQty: number;
    productUpdatable: boolean;
    sequence: number;
    state: string;
    subscriptionId: number;

    public static getInstanceFromResponse(response: any): OrderLineModel {
        const model: OrderLineModel = new OrderLineModel();
        model.id = BaseModel.getValue(response.id);
        model.orderId = BaseModel.getValue(response.orderId);
        model.name = BaseModel.getValue(response.name);
        if (response.product) {
            model.product = {
                id: BaseModel.getValue(response.product.id),
                defaultCode: BaseModel.getValue(response.product.defaultCode),
                name: BaseModel.getValue(response.product.name),
                pricesExtra: BaseModel.getValue(response.product.pricesExtra),
                lstPrice: BaseModel.getValue(response.product.lstPrice),
                priceExtra: BaseModel.getValue(response.product.priceExtra)
            } as OrderProduct;
        }
        model.priceUnit = BaseModel.getValue(response.priceUnit);
        model.priceReduce = BaseModel.getValue(response.priceReduce);
        model.priceSubtotal = BaseModel.getValue(response.priceSubtotal);
        model.priceTax = BaseModel.getValue(response.priceTax);
        model.priceTotal = BaseModel.getValue(response.priceTotal);
        if (response.productUom) {
            model.productUom = {
                id: BaseModel.getValue(response.productUom.id),
                name: BaseModel.getValue(response.productUom.name)
            } as OrderUom;
        }
        model.productUomQty = BaseModel.getValue(response.productUomQty);
        model.productUpdatable = response.productUpdatable;
        model.sequence = BaseModel.getValue(response.sequence);
        model.state = BaseModel.getValue(response.state);
        model.subscriptionId = BaseModel.getValue(response.subscriptionId);
        return model;
    }

    public static getInstancesFromResponse(responses: any): OrderLineModel[] {
        const models: OrderLineModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: OrderLineModel = OrderLineModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}


export interface OrderProduct {
    id: number;
    defaultCode: string;
    name: string;
    pricesExtra: string;
    lstPrice: number;
    priceExtra: number;
}

export interface OrderUom {
    id: number;
    name: string;
}
