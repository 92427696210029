import { BaseModel } from './base.model';

/**
 * Address of Dealer, Customer and Customer Facility
 */
export class AddressModel extends BaseModel {
    id: string;
    type: string;
    street: string;
    street2: string;
    city: string;
    state: string;
    stateCode: string;
    country: string;
    countryCode: string;
    zip: string;
    preferred: boolean;

    fullAddress: string;

    public static getNewInstance(preferred?: boolean): AddressModel {
        const model = new AddressModel();
        model.preferred = preferred;
        model.countryCode = 'DE';
        return model;
    }

    public static getInstanceFromResponse(response: any): AddressModel {
        const address: AddressModel = new AddressModel();
        address.id = BaseModel.getValue(response.id);
        address.type = BaseModel.getValue(response.type);
        address.street = BaseModel.getValue(response.street);
        address.street2 = BaseModel.getValue(response.street2);
        address.city = BaseModel.getValue(response.city);
        address.state = BaseModel.getValue(response.state);
        address.stateCode = BaseModel.getValue(response.stateCode);
        address.country = BaseModel.getValue(response.country);
        address.countryCode = BaseModel.getValue(response.countryCode);
        address.zip = BaseModel.getValue(response.zip);
        address.preferred = BaseModel.getValue(response.preferred);
        AddressModel.setFullAddress(address);
        return address;
    }

    public static getInstancesFromResponse(responses: any[]): AddressModel[] {
        const addresses: AddressModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const address: AddressModel = AddressModel.getInstanceFromResponse(response);
                addresses.push(address);
            });
        }
        return addresses;
    }

    public static getInstance(place: google.maps.places.PlaceResult, oldAddress?: AddressModel): AddressModel {
        const address = new AddressModel();
        for (let i = place.address_components.length - 1; i >= 0; i--) {
            const addressType = place.address_components[i].types[0];
            const addressLine = place.address_components[i].long_name;
            if (addressType === 'postal_code') {
                address.zip = addressLine;
            } else if (addressType === 'country') {
                if (place.address_components[i].short_name) {
                    address.country = place.address_components[i].short_name.toLowerCase();
                }
            } else if (addressType === 'administrative_area_level_1') {
                address.state = addressLine;
            } else if (addressType === 'locality') {
                address.city = addressLine;
            } else if (addressType === 'sublocality_level_1') {
                address.street2 = addressLine;
            } else if (addressType === 'sublocality_level_2') {
                address.street = addressLine;
            } else if (addressType === 'route') {
                address.street = addressLine;
            } else if (addressType === 'street_number') {
                address.street = addressLine + (address.street == null ? '' : (' ' + address.street));
            } else if (addressType === 'premise') {
                address.street = addressLine;
            }
        }

        return address;
    }

    public static clone(model: AddressModel): AddressModel {
        const cloned = new AddressModel();
        cloned.id = model.id;
        cloned.type = model.type;
        cloned.street = model.street;
        cloned.street2 = model.street2;
        cloned.city = model.city;
        cloned.state = model.state;
        cloned.stateCode = model.stateCode;
        cloned.country = model.country;
        cloned.countryCode = model.countryCode;
        cloned.zip = model.zip;
        cloned.preferred = model.preferred;
        cloned.fullAddress = model.fullAddress;
        return cloned;
    }

    public static setFullAddress(address: AddressModel) {
        address.fullAddress = '';
         if (address.street != null && address.street !== '') {
            address.fullAddress += address.street + ' ';
         }
         if (address.street2 != null && address.street2 !== '') {
            address.fullAddress += address.street2 + ' ';
         }
         if (address.city != null && address.city !== '') {
            address.fullAddress += address.city + ' ';
         }
         if (address.state != null && address.state !== '') {
            address.fullAddress += address.state + ' ';
         }
         if (address.zip != null && address.zip !== '') {
            address.fullAddress += address.zip + ' ';
         }
         if (address.country != null && address.country !== '') {
            address.fullAddress += address.country + ' ';
         }
    }
}
