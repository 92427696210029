import { BaseModel } from './base.model';

export class CountryModel extends BaseModel {
    id: string;
    code: string;
    name: string;


    public static getInstanceFromResponse(response: any): CountryModel {
        const model: CountryModel = new CountryModel();
        model.id = BaseModel.getValue(response.id);
        model.code = BaseModel.getValue(response.code);
        model.name = BaseModel.getValue(response.name);

        return model;
    }

    public static getInstancesFromResponse(responses: any): CountryModel[] {
        const models: CountryModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: CountryModel = CountryModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}


export class CountryStateModel extends BaseModel {
    id: string;
    code: string;
    name: string;
    country: CountryModel;


    public static getInstanceFromResponse(response: any): CountryStateModel {
        const model: CountryStateModel = new CountryStateModel();
        model.id = BaseModel.getValue(response.id);
        model.code = BaseModel.getValue(response.code);
        model.name = BaseModel.getValue(response.name);
        const countryModel = new CountryModel();
        countryModel.id = BaseModel.getValue(response.countryId);
        countryModel.code = BaseModel.getValue(response.countryCode);
        countryModel.name = BaseModel.getValue(response.countryName);

        return model;
    }

    public static getInstancesFromResponse(responses: any): CountryStateModel[] {
        const models: CountryStateModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: CountryStateModel = CountryStateModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}
