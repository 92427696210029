import { BaseModel } from './base.model';

export class PaymentMethodsModel extends BaseModel {
  id: number;
  paymentMethod: string;

  // DD
  iban: string;
  bankBic: string;
  accHolderName: string;
  mandateRegistrationRef: string;
  mandateSignatureDate: Date;
  bankName: string;

  // CC
  cardPseudoNumber: string;
  cardExpiry: string;
  cardHolderName: string;

  operation: string;
  changed: boolean;
  partnerId: number;

  public static getInstanceFromResponse(response: any): PaymentMethodsModel {
    const paymentMethod: PaymentMethodsModel = new PaymentMethodsModel();
    paymentMethod.id = response.id;
    paymentMethod.paymentMethod = BaseModel.getValue(response.paymentMethod);

    paymentMethod.iban = BaseModel.getValue(response.iban);
    paymentMethod.bankBic = BaseModel.getValue(response.bankBic);
    paymentMethod.accHolderName = BaseModel.getValue(response.accHolderName);
    paymentMethod.mandateRegistrationRef = BaseModel.getValue(response.mandateRegistrationRef);
    paymentMethod.mandateSignatureDate = BaseModel.getDate(response.mandateSignatureDate);
    paymentMethod.bankName = BaseModel.getValue(response.bankName);

    paymentMethod.cardPseudoNumber = BaseModel.getValue(response.cardPseudoNumber);
    paymentMethod.cardExpiry = BaseModel.getValue(response.cardExpiry);
    paymentMethod.cardHolderName = BaseModel.getValue(response.cardHolderName);
    paymentMethod.partnerId = BaseModel.getValue(response.partnerId);

    return paymentMethod;

  }

  public static getInstancesFromResponse(responses: any[]): PaymentMethodsModel[] {
    const paymentMethods: PaymentMethodsModel[] = [];
    if (responses) {
      responses.forEach(response => {
        const paymentMethod: PaymentMethodsModel = PaymentMethodsModel.getInstanceFromResponse(response);
        paymentMethods.push(paymentMethod);
      });
    }
    return paymentMethods;
  }


  public static clone(model: PaymentMethodsModel): PaymentMethodsModel {
    const cloned = new PaymentMethodsModel();
    cloned.id = model.id;
    cloned.paymentMethod = model.paymentMethod;

    cloned.iban = model.iban;
    cloned.bankBic = model.bankBic;
    cloned.accHolderName = model.accHolderName;
    cloned.mandateRegistrationRef = model.mandateRegistrationRef;
    cloned.mandateSignatureDate = model.mandateSignatureDate;
    cloned.bankName = model.bankName;

    cloned.cardPseudoNumber = model.cardPseudoNumber;
    cloned.cardExpiry = model.cardExpiry;
    cloned.cardHolderName = model.cardHolderName;
    cloned.partnerId = model.partnerId;
    return cloned;
  }

  public static isEmpty(model: PaymentMethodsModel): boolean {
    let empty = false;
    if (model.paymentMethod === 'DD') {
      if (
        (model.iban == null || model.iban === '')
          && (model.bankBic == null || model.bankBic === '')
          && (model.accHolderName == null || model.accHolderName === '')
          && (model.mandateRegistrationRef == null || model.mandateRegistrationRef === '')
          && (model.mandateSignatureDate == null)
        ) {
          empty = true;
      }
    } else {
      if (
        (model.cardPseudoNumber == null || model.cardPseudoNumber === '')
          && (model.cardExpiry == null || model.cardExpiry === '')
          && (model.cardHolderName == null || model.cardHolderName === '')
        ) {
          empty = true;
      }
    }
    return empty;
  }

}
