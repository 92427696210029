import { Component, OnDestroy, Inject, OnInit, ViewChild, ElementRef, HostListener  } from '@angular/core';
import { DatePipe, DOCUMENT, Location } from '@angular/common';
import { NavData } from '../../_nav';
import { AppContext } from '../../app.context';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../models/user.model';
import { MenuService } from '../../views/login/menu.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('customNotification', { static: true }) customNotificationTmpl;

  public navItems: NavData[] = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public user: UserModel;
  public criteria: string;

  notificationTitles: string[];
  titleIndex = 0;

  @ViewChild('header', {static: true, read: ElementRef})
  header: ElementRef;

  @ViewChild('footer', {static: true, read: ElementRef})
  footer: ElementRef;

  @ViewChild('topMenu', {static: true, read: ElementRef})
  topMenu: ElementRef;

  constructor(
    public context: AppContext,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _location: Location,
    private menuService: MenuService,
    @Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.user = this.context.getLoggedInUser();
    if (this.user == null) {
      let url = location.hash;
      if (url) {
        url = url.substr(1);
        this.context.set('auto-login-url', url);
      }
      // this.autoLogin();
    }

    if (context.getUserMenuItems() == null || context.getUserMenuItems().length === 0) {
      this.menuService.getMenu(null).subscribe(navData => {
        this.context.setUserMenuItems(navData);
      });
    }
  }

  ngOnInit(): void {
    this.navItems = this.context.getUserMenuItems();
    setTimeout(() => {
      this.windowResize();
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  /**
   * Logsout user from the application
   */
  logout($event?: any) {
    this._postLogout();
  }

  _postLogout() {
    this.context.clearContext();
    document.body.classList.remove('sidebar-show');
    window.location.href = window.location.href.substr(0, window.location.href.indexOf('#') - 1) + '/index.html';
  }

  /**
   * Try to auto login
   */
  autoLogin() {
    this.router.navigate(['auto-login']);
  }

  /**
   * Navigates to Change Password page
   */
  changePassword($event: any) {
    this.router.navigate(['settings/change-password']);
  }

   /**
   * Navigates to Update Profile page
   */
  updateProfile($event: any) {
    this.router.navigate(['settings/profile']);
  }


  gotoHome($event: any) {
    this.router.navigate([`home`]);
  }

  goBack($event: any) {
    this._location.back();
  }

  canGoBack() {
    let goback = true;
    if (this._location.path() === '/features/dashboards/my-dashboard') {
      goback = false;
    }

    return goback;
  }

  @HostListener('window:resize', ['$event'])
  windowResize($event?: any) {
    this.setContentSize();
    setTimeout(() => {
      this.setContentSize();
    });
  }

  setContentSize() {
    this.context.windowHeight = window.innerHeight;
    const windowHeight = window.innerHeight;
    const headerHeight = this.header.nativeElement.offsetHeight;
    const footerHeight = this.footer.nativeElement.offsetHeight;
    this.context.contentHeight = windowHeight - headerHeight - footerHeight;
  }

  createNewContact($event: any) {
    this.router.navigate(['features/orders/new']);
  }
}
