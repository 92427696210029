import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'contactType'
})
export class ContactTypePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {

  }
  transform(value: string) {
    if (value) {
      if (value === 'contact') {
        return 'Postal';
      } else {
        return this.titleCasePipe.transform(value);
      }
    } else {
      return '';
    }
  }
}
