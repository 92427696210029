import { Pipe, PipeTransform } from '@angular/core';
import { OrderModel } from '../models/order.model';
import { SubscriptionModel } from '../models/subscription.model';

@Pipe({
    name: 'orders'
})
export class OrdersFilter  implements PipeTransform {
    transform(orders: OrderModel[], subscriptions: SubscriptionModel[]) {
        const filtered: OrderModel[] = [];
        if (orders != null && orders.length > 0) {
            orders.forEach(order => {
                if (subscriptions && subscriptions.length > 0) {
                    let matched = false;
                    for (let s = 0; s < subscriptions.length; s++) {
                        const subscription = subscriptions[s];
                        const index = order.orderLines.findIndex(x => x.subscriptionId === subscription.id);
                        if (index >= 0 || subscription.orderId === order.id) {
                            matched = true;
                            filtered.push(order);
                            break;
                        }
                    }
                } else {
                    filtered.push(order);
                }
            });
        }
        return filtered;
    }
}
