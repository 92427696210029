import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'unit'
})
export class UnitPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {

  }
  transform(value) {
    if (value && value.toLowerCase() !== 'n/a') {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    } else {
      return '';
    }
  }
}
