/**
 * Base Class for all the Service. Handles the http call.
 * TODO: Change to HTTP only for prototype we use the local storage
 */

// 3rd Party
import { Observable } from 'rxjs';

// Application Specific
import { IService } from './service.interface';
import { Injector } from '@angular/core';
import { AppContext } from '../app.context';

export abstract class MockBaseService implements IService {

    context: AppContext;
    constructor(injector: Injector) {
        this.context = injector.get(AppContext);
    }

    _get(url: string): Observable<any> {
        const obserable = new Observable<any>(subscriber => {
            const idObj = this.parseUrl(url);
            const item = this.getItem(idObj);
            subscriber.next(item);
        });
        return obserable;
    }

    _post(url: string, data: any): Observable<any> {
        const obserable = new Observable<any>(subscriber => {
            const idObj = this.parseUrl(url);
            const item = this.setItem(idObj, data);
            subscriber.next(item);
        });
        return obserable;
    }

    _put(url: string, data: any): Observable<any> {
        return this._post(url, data);
    }

    _delete(url: string): Observable<any> {
        const obserable = new Observable<any>(subscriber => {
            const idObj = this.parseUrl(url);
            const items = this.deleteItem(idObj);
            subscriber.next(items);
        });
        return obserable;
    }

    setItems(object: string, items: any[], override: boolean = true) {
        if (override) {
            localStorage.setItem(object, JSON.stringify(items));
        } else {
            if (localStorage.getItem(object) == null) {
                localStorage.setItem(object, JSON.stringify(items));
            }
        }
    }
    private getItem(idObj: any): any {
        const itemsString = localStorage.getItem(idObj.object);
        if (itemsString) {
            const items = JSON.parse(itemsString);
            if (idObj[this.getIdField()]) {
                let itemToReturn: any = null;
                items.forEach(item => {
                    if (item[this.getIdField()] === idObj[this.getIdField()]) {
                        itemToReturn = item;
                    }
                });
                return itemToReturn;
            } else if (idObj.params && idObj.params.length > 0) {
                const filteredItems = [];
                items.forEach(item => {
                    let matched = true;
                    idObj.params.forEach(param => {
                        if (item[param.key] !== param.value) {
                            matched = false;
                        }
                    });
                    if (matched) {
                        filteredItems.push(item);
                    }
                });
                return filteredItems;
            } else {
                return items;
            }
        }
        return null;
    }

    private setItem(idObj: any, item: any): any {
        let items = this.getItem(idObj);
        if (items == null) {
            items = [];
        }

        let index = -1;
        items.forEach((iitem, i) => {
            if (item[this.getIdField()] === iitem[this.getIdField()]) {
                index = i;
            }
        });
        if (index >= 0) {
            items[index] = item;
        } else {
            items.push(item);
        }
        this.setItems(idObj.object, items);
        return item;
    }

    private deleteItem(idObj: any) {
        let items = this.getItem({
            object: idObj.object
        });
        if (items == null) {
            items = [];
        }

        let index = -1;
        items.forEach((iitem, i) => {
            if (idObj[this.getIdField()] === iitem[this.getIdField()]) {
                index = i;
            }
        });

        if (index >= 0) {
            items.splice(index, 1);
        }
        this.setItems(idObj.object, items);
        return items;
    }

    private parseUrl(url: string): any {
        const urlAndParams =  url.split('?');
        const urlParts = urlAndParams[0].split('/');
        const data = {
            object: urlParts[0]
        };
        if (urlParts.length > 1) {
            data[this.getIdField()] = urlParts[1];
        }
        if (urlAndParams.length > 1 && urlAndParams[1].trim().length > 0) {
            const paramsPart = urlAndParams[1].split('&');
            const params = [];
            paramsPart.forEach(paramPart => {
                const keyValue = paramPart.split('=');
                if (keyValue.length === 2) {
                    params.push({
                        key: keyValue[0],
                        value: keyValue[1]
                    });
                }
            });
            data['params'] = params;
        }
        return data;
    }

    abstract getIdField(): string;

}
