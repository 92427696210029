import { BaseModel } from './base.model';

export class CommunicationModel extends BaseModel {
    id: number;
    name: string;
    partnerId: number;
    partnerName: string;
    subscriptionId: number;
    subscriptionReferenceId: string;
    createDate: Date;
    communicationSubTypeId: number;
    communicationSubTypeName: string;
    channel: string;
    email: string;
    jsonData: string;
    csvData: string;
    dateSent: Date;
    canSend: boolean;
    canReprint: boolean;
    canNewprint: boolean;

    public static getInstanceFromResponse(response: any): CommunicationModel {
        const communication: CommunicationModel = new CommunicationModel();
        communication.id = BaseModel.getValue(response.id);
        communication.name = BaseModel.getValue(response.name);
        communication.partnerId = BaseModel.getValue(response.partnerId);
        communication.partnerName = BaseModel.getValue(response.partnerName);
        communication.subscriptionId = BaseModel.getValue(response.subscriptionId);
        communication.subscriptionReferenceId = BaseModel.getValue(response.subscriptionReferenceId);
        communication.createDate = BaseModel.getDate(response.createDate);
        communication.communicationSubTypeId = BaseModel.getValue(response.communicationSubTypeId);
        communication.communicationSubTypeName = BaseModel.getValue(response.communicationSubTypeName);
        communication.channel = BaseModel.getValue(response.channel);
        communication.email = BaseModel.getValue(response.email);
        communication.jsonData = BaseModel.getValue(response.jsonData);
        communication.dateSent = BaseModel.getDate(response.dateSent);
        communication.csvData = BaseModel.getValue(response.csvData);
        communication.canSend = BaseModel.getValue(response.canSend);
        communication.canReprint = BaseModel.getValue(response.canReprint);
        communication.canNewprint = BaseModel.getValue(response.canNewprint);
        return communication;
    }

    public static getInstancesFromResponse(responses: any[]): CommunicationModel[] {
        const communications: CommunicationModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const communication: CommunicationModel = CommunicationModel.getInstanceFromResponse(response);
                communications.push(communication);
            });
        }
        return communications;
    }

}

export interface CommunicationReprintRequest {
    communicationId: number;
    alternateEmailId?: string;
}


export interface CommunicationNewprintRequest {
    communicationId: number;
    notificationType: string;
}
