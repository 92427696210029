import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationModel } from '../models/communication.model';
import { SubscriptionModel } from '../models/subscription.model';

@Pipe({
    name: 'communications'
})
export class CommunicationsFilter  implements PipeTransform {
    transform(communications: CommunicationModel[], subscriptions: SubscriptionModel[]) {
        const filtered: CommunicationModel[] = [];
        if (communications != null && communications.length > 0) {
            communications.forEach(communication => {
                if (subscriptions && subscriptions.length > 0) {
                    for (let s = 0; s < subscriptions.length; s++) {
                        const subscription = subscriptions[s];
                        if (subscription.id === communication.subscriptionId) {
                            filtered.push(communication);
                            break;
                        }
                    }
                } else {
                    filtered.push(communication);
                }
            });
        }
        return filtered;
    }
}
