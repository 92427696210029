import { Component, ComponentFactoryResolver } from '@angular/core';
import { UiBaseComponent } from '../ui-base.component';

@Component({
    selector: 'app-ui-image',
    templateUrl: 'ui-image.component.html',
    styleUrls: ['../ui-base.component.scss', 'ui-image.component.scss']
})
export class UiImageComponent extends UiBaseComponent {

}
