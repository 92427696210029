import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionModel } from '../models/subscription.model';
import { TransactionModel } from '../models/transaction.model';

@Pipe({
    name: 'transactions'
})
export class TransactionFilter  implements PipeTransform {
    transform(transactions: TransactionModel[], transactionType: string, subscriptions: SubscriptionModel[]) {
        const filtered: TransactionModel[] = [];
        if (transactions != null && transactions.length > 0) {
            transactions.forEach(transaction => {
                if (transaction.transactionType === transactionType) {
                    if (subscriptions && subscriptions.length > 0) {
                        const index = subscriptions.findIndex(x => x.id + '' === transaction.subscriptionId);
                        if (index >= 0) {
                            filtered.push(transaction);
                        }
                    } else {
                        filtered.push(transaction);
                    }
                }
            });
        }
        return filtered;
    }
}
