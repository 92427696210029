import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from '../models/contact.model';
import { ContactSearchCriteriaModel } from '../models/contact-search-criteria.model';
import { OdooHttpBaseService } from './odoo-http-base.service';
import { DatePipe } from '@angular/common';
import { PaymentMethodsModel } from '../models/payment-methods.model';

export class ContactService extends OdooHttpBaseService {
    baseUrl = 'contact';

    salutations: any[] = [
        { label: 'Mr', value: 'MR'},
        { label: 'Mrs', value: 'MRS'}
    ];

    countries: any[] = [
        {label: 'COUNTRY', value: ''},
        {label: 'DE', value: 'DE'},
        {label: 'FR', value: 'FR'},
        {label: 'EN', value: 'EN'},
    ];

    paymentMethods: any[] = [
        {label: 'Payment Method', value: ''},
        {label: 'CC', value: 'CC'},
        {label: 'SEPA', value: 'SEPA'}
    ];

    statuses: any[] = [
        {label: 'State', value: ''},
        {label: 'New', value: 'new'},
        {label: 'Active', value: 'ACTIVE'}
    ];

    datePipe: DatePipe;
    constructor(injector: Injector) {
        super(injector);
        this.datePipe = injector.get(DatePipe)
    }
    getSalutations(): Observable<string[]> {
        const observable = new Observable<string[]>(subscriber => {
            subscriber.next(this.salutations);
        });
        return observable;
    }

    getCountries(): Observable<any[]> {
        const observable = new Observable<any[]>(subscriber => {
            subscriber.next(this.countries);
        });
        return observable;
    }

    getPaymentMethods(): Observable<any[]> {
        const observable = new Observable<any[]>(subscriber => {
            subscriber.next(this.paymentMethods);
        });
        return observable;
    }

    getStatuses(): Observable<any[]> {
        const observable = new Observable<any[]>(subscriber => {
            subscriber.next(this.statuses);
        });
        return observable;
    }

    get(id: number): Observable<ContactModel> {
        const observable = new Observable<ContactModel>(subscriber => {
            this._post(`${this.baseUrl}/${id}/get`, {}).subscribe(item => {
                if (item != null) {
                    const contact = ContactModel.getInstanceFromResponse(item);
                    subscriber.next(contact);
                } else {
                    subscriber.error(`Contact with Id ${id} does not exists`);
                }
            });
        });
        return observable;
    }

    search(criteria: any[]): Observable<ContactModel[]> {
        const observable = new Observable<ContactModel[]>(subscriber => {
            this._post(`${this.baseUrl}/search`, {criteria: criteria}).subscribe(items => {
                if (items != null) {
                    const contacts = ContactModel.getInstancesFromResponse(items);
                    subscriber.next(contacts);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    save(contact: ContactModel): Observable<ContactModel> {
        const request = ContactModel.getRequest(contact);
        request.dateOfBirth = this.datePipe.transform(request.dateOfBirth, 'yyyy-MM-dd');
        const observable = new Observable<ContactModel>(subscriber => {
            this._post(`${this.baseUrl}`, request).subscribe(response => {
                contact = ContactModel.getInstanceFromResponse(response);
                subscriber.next(contact);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    block(contact: ContactModel): Observable<ContactModel> {
        const observable = new Observable<ContactModel>(subscriber => {
            const request = {
                id: contact.id,
                blockedMessage: contact.blockedMessage
            };
            this._post(`${this.baseUrl}/block`, request).subscribe(response => {
                contact = ContactModel.getInstanceFromResponse(response);
                subscriber.next(contact);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    unblock(contact: ContactModel): Observable<ContactModel> {
        const observable = new Observable<ContactModel>(subscriber => {
            const request = {
                id: contact.id,
                blockedMessage: contact.blockedMessage
            };
            this._post(`${this.baseUrl}/unblock`, request).subscribe(response => {
                contact = ContactModel.getInstanceFromResponse(response);
                subscriber.next(contact);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    savePaymentMethod(payentMethod: PaymentMethodsModel): Observable<ContactModel> {
        const observable = new Observable<ContactModel>(subscriber => {
            this._post(`${this.baseUrl}/payment_method`, payentMethod).subscribe(() => {
                this.get(payentMethod.partnerId).subscribe(contact => {
                    subscriber.next(contact);
                })
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

}
