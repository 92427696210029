import { BaseModel } from './base.model';

export class ComplaintModel extends BaseModel {
    id: string;
    complaintOrigin: string;
    partnerId: number;
    partnerName: string;
    subscriptionId: number;
    subscriptionReferenceId: string;
    periodStart: Date;
    complaintType: string;
    channelType: string;
    complaintDate: Date;
    userId: number;
    userName: string;
    complaintContent: string;

    public static getInstanceFromResponse(response: any): ComplaintModel {
        const model: ComplaintModel = new ComplaintModel();
        model.id = BaseModel.getValue(response.id);
        model.complaintOrigin = BaseModel.getValue(response.complaintOrigin);
        model.partnerId = BaseModel.getValue(response.partnerId);
        model.partnerName = BaseModel.getValue(response.partnerName);
        model.partnerId = BaseModel.getValue(response.partnerId);
        model.periodStart = BaseModel.getDate(response.periodStart);
        model.complaintType = BaseModel.getValue(response.complaintType);
        model.complaintDate = BaseModel.getDate(response.complaintDate);
        model.channelType = BaseModel.getValue(response.channelType);
        model.userId = BaseModel.getValue(response.userId);
        model.userName = BaseModel.getValue(response.userName);
        model.complaintContent = BaseModel.getValue(response.complaintContent);
        model.subscriptionId = BaseModel.getValue(response.subscriptionId);
        model.subscriptionReferenceId = BaseModel.getValue(response.referenceId);
        return model;
    }

    public static getInstancesFromResponse(responses: any): ComplaintModel[] {
        const models: ComplaintModel[] = [];
        if (responses) {
            responses.forEach((response, index) => {
                const model: ComplaintModel = ComplaintModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }

    public static clone(model: ComplaintModel) {
        const cloned = new ComplaintModel();
        cloned.id = model.id;
        cloned.complaintOrigin = model.complaintOrigin;
        cloned.partnerId = model.partnerId;
        cloned.partnerName = model.partnerName;
        cloned.partnerId = model.partnerId;
        cloned.periodStart = model.periodStart;
        cloned.complaintType = model.complaintType;
        cloned.complaintDate = model.complaintDate;
        cloned.channelType = model.channelType;
        cloned.userId = model.userId;
        cloned.userName = model.userName;
        cloned.complaintContent = model.complaintContent;
        cloned.subscriptionId = model.subscriptionId;
        cloned.subscriptionReferenceId = model.subscriptionReferenceId;


        return cloned;
    }
}


export interface ComplaintSubscription {
    id: number;
    name: string;
    referenceId: string;
}

