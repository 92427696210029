import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'products'
})
export class ProductsFilter  implements PipeTransform {
    transform(products: any[]) {
        const filtered: any[] = [];
        if (products != null && products.length > 0) {
            products.forEach(product => {
                filtered.push(product);
            });
        }
        return filtered;
    }
}
