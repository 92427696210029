import { Input, OnInit, OnChanges } from '@angular/core';
import { UiCell } from '../ui-renderer.interfaces';
import { CapitalizeWordsPipe } from '../../../../pipes/capitalize-words.pipe';

export class UiBaseComponent implements OnInit, OnChanges {
    // tslint:disable-next-line:no-input-rename
    @Input('meta') meta: UiCell;
    // tslint:disable-next-line:no-input-rename
    @Input('data') data: any;

    value: any = '';
    title: string = '';

    constructor(private capitalizeWordsPipe: CapitalizeWordsPipe) {

    }

    ngOnInit() {
        this.initalizeValue();
    }

    ngOnChanges() {
        this.initalizeValue();
    }

    initalizeValue(): any {
        this.title = this.getTitle();
        this.value = this.getValue('object', 'field');
        if (this.value == null || this.value === '') {
            this.value = this.getValue('alternateObject', 'alternateField');
        }
    }

    getValue(object: string, field: string) {
        if (this.data && this.meta && this.meta.data) {
            let value = this.data;
            if (this.meta.data[object] && this.meta.data[object] !== '') {
                value = value[this.meta.data[object]];
            }

            if (this.meta.data[field] && this.meta.data[field] !== '') {
                if (value[this.meta.data[field]]) {
                    value = value[this.meta.data[field]];
                } else if (value.additionalProperties) {
                    value = value.additionalProperties[this.meta.data[field]];
                }
                if (this.meta.data.type === 'boolean') {
                    if (!value || value === '') {
                        value = 'No';
                    } else {
                        value = 'Yes';
                    }
                } else if (this.meta.data.type === 'number') {
                    if (!value || value === '') {
                        value = 0;
                    }
                } else {
                    if (!value) {
                        value = '';
                    }
                }
            }
            if (typeof value === 'object') {
                value = '';
            }
            return value;
        }
    }

    getTitle() {
        let title = '';
        if (this.data && this.meta && this.meta.data) {
            if (this.meta.data.title) {
                title = this.meta.data.title;
            } else {
                title = this.capitalizeWordsPipe.transform(this.meta.data.field);
            }
        }
        return title;
    }
}
