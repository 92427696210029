import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TransactionSearchCriteriaModel } from '../models/transaction-search-criteria.model';
import { TransactionModel } from '../models/transaction.model';
import { MockBaseService } from './mock.base.service';
import { TRANSACTIONS } from './transaction-test-data';

@Injectable()
export class TransactionSerivce extends MockBaseService {
    baseUrl = 'transactions';
    constructor(injector: Injector) {
        super(injector);
        this.generateTestData();
    }

    getIdField(): string {
        return 'transactionId';
    }


    get(transactionId: string): Observable<TransactionModel> {
        const observable = new Observable<TransactionModel>(subscriber => {
            this._get(`${this.baseUrl}/${transactionId}`).subscribe(item => {
                if (item != null) {
                    const transaction = TransactionModel.getInstanceFromResponse(item);
                    subscriber.next(transaction);
                } else {
                    subscriber.error(`Transaction with Id ${transactionId} does not exists`);
                }
            });
        });
        return observable;
    }

    private getAll(): Observable<TransactionModel[]> {
        const observable = new Observable<TransactionModel[]>(subscriber => {
            this._get(`${this.baseUrl}`).subscribe(items => {
                if (items != null) {
                    const transactions = TransactionModel.getInstancesFromResponse(items);
                    subscriber.next(transactions);
                } else {
                    subscriber.next(null);
                }
            });
        });
        return observable;
    }

    private filter(transactions: TransactionModel[], criteria: TransactionSearchCriteriaModel): TransactionModel[] {
        let filteredTransactions: TransactionModel[] = null;
        if (transactions != null && transactions.length > 0 && criteria != null) {
            filteredTransactions = [];
            transactions.forEach(transaction => {
                if (this.context.isLike(transaction.contactId, criteria.contactId + '')
                    && this.context.isLike(transaction.subscriptionId, criteria.subscriptionId)
                    && this.context.isLike(transaction.transactionType, criteria.transactionType)
                    && this.context.isLike(transaction.status, criteria.status)) {
                        filteredTransactions.push(transaction);
                }
            });
        } else {
            filteredTransactions = transactions;
        }

        return filteredTransactions;
    }


    search(criteria?: TransactionSearchCriteriaModel): Observable<TransactionModel[]> {
        if (criteria == null) {
            criteria = new TransactionSearchCriteriaModel();
        }
        const observable = new Observable<TransactionModel[]>(subscriber => {
            this.getAll().subscribe(transactions => {
                transactions = this.filter(transactions, criteria);
                subscriber.next(transactions);
            });
        });
        return observable;
    }



    generateTestData() {
        const transactions = TRANSACTIONS;
        this.setItems(this.baseUrl, transactions);
    }

}
