/**
 * Odoo Services must extend this class
 */

// Angular
import { Injector } from '@angular/core';

// Application Specific
import { HttpBaseService } from './http-base.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

export class OdooHttpBaseService extends HttpBaseService {
    private router: Router;
    constructor(injector: Injector) {
        super(injector);
        // this.router = injector.get(Router);
    }

    _get(url: string): Observable<any> {
        const observable = new Observable<any>(subscriber => {
            const params = {
                id: this.context.getToken(),
                jsonrpc: 2.0,
                params: null
            };
           super._get(url, JSON.stringify(params)).subscribe(response => {
               this.parseResponse(response, subscriber);
           }, error => {
               subscriber.error(error);
           });
        });
        return observable;
    }

    _post(url: string, data: any): Observable<any> {
        const observable = new Observable<any>(subscriber => {
            const params = {
                id: this.context.getToken(),
                jsonrpc: 2.0,
                params: data
            };
           super._post(url, params).subscribe(response => {
               this.parseResponse(response, subscriber);
           }, error => {
               subscriber.error(error);
           });
        });
        return observable;
    }

    _put(url: string, data: any): Observable<any> {
        const observable = new Observable<any>(subscriber => {
            const params = {
                id: this.context.getToken(),
                jsonrpc: 2.0,
                params: data
            };
           super._put(url, params).subscribe(response => {
               this.parseResponse(response, subscriber);
           }, error => {
               subscriber.error(error);
           });
        });
        return observable;
    }

    _delete(url: string): Observable<any> {
        const observable = new Observable<any>(subscriber => {
            const params = {
                id: this.context.getToken(),
                jsonrpc: 2.0,
                params: null
            };
           super._delete(url, params).subscribe(response => {
               this.parseResponse(response, subscriber);
           }, error => {
               subscriber.error(error);
           });
        });
        return observable;
    }

    getUrl(url: string): string {
        if (!url.startsWith('/')) {
            url = `/${url}`;
        }
        url = `${environment.odooApiBaseUrl}${url}`;
        return url;
    }

    _getOptions() {
        const options: any = {};
        options['withCredentials'] = true;
        options['observe'] = 'response' as 'response';
        options['headers'] = {
            'Content-Type': 'application/json'
        };

        const token = this.context.getToken();
        if (token) {
            options['headers']['Cookie'] = 'session_id=' + token;
        }
        
        options['headers']['x_odoo_dbfilter'] = 'o2o_odoo';
        options['headers']['X_ODOO_DBFILTER'] = 'o2o_odoo';

        return options;
    }

    parseResponse(response: any, subscriber: any) {
        if (response) {
            if (response.body) {
                response = response.body;
            }
            if (response.id) {
                this.context.setToken(response.id)
            }
            if (response.error) {
                if (response.error.code === 100) {
                    // this.router.navigate(['402']);
                    const url = location.href;               // Save down the URL without hash.
                    location.href = '#/login';                 // Go to the target element.
                    //history.replaceState(null, null, location.href);   // Don't like hashes. Changing it back.
                } else {
                    if (response.error) {
                        if (response.error.data) {
                            if (response.error.data.message) {
                                if (response.error.data.message.endsWith('\n')) {
                                    // tslint:disable-next-line:max-line-length
                                    response.error.data.message = response.error.data.message.substr(0, response.error.data.message.length - 1);
                                }
                                let message = response.error.data.message;
                                message = message.replaceAll('None', 'null');
                                try {
                                    message = (0, eval)('(' + message + ')');
                                } catch (exception) {
                                    // do nothing
                                }
                                if (typeof message !== 'string') {
                                    if (message.message_id) {
                                        message = message.message_id;
                                    } else {
                                        message = message.message;
                                    }
                                    response.error.data.message = message;
                                }
                            }
                            subscriber.error(response.error.data);
                        } else {
                            subscriber.error(response.error);
                        }
                    } else {
                        subscriber.error(response);
                    }
                }
            } else {
                subscriber.next(response.result);
            }
        }
    }
}
