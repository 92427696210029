/**
 * Shared Module, the module imports I18NModule should add TranslatorPipe in the Provider list
 */

// Angular
import { NgModule  } from '@angular/core';

// Application Specific
import { TranslatorPipe } from './translator.pipe';


@NgModule({
    declarations: [
        TranslatorPipe
    ],
    exports: [
        TranslatorPipe
    ],
    providers: [
        
    ]
})
export class I18NModule {

}
