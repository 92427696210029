/**
 * AppContext provides the context at application level.
 * This can be used to share the data and cache the models, etc.
 */

 // Angular
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

// 3rd Party
import { Subject, Observable } from 'rxjs';
import { NavData } from './_nav';
import { UserModel } from './models/user.model';
import { ToastrService } from 'ngx-toastr';
import { errorMessages } from './_error-messages';
import { Guid } from 'guid-typescript';
import { DatePipe } from '@angular/common';
import { SubscriptionModel } from './models/subscription.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslatorPipe } from './i18n/translator.pipe';

@Injectable()
export class AppContext {
    version = '1.1.6';
    

    pageSize: number = 10;

    windowHeight: number;
    contentHeight: number;
    wizardContentHeight: number;
    quickFilterHeight: number = 30;
    selectedMenuItem: NavData;
    defaultLogo = 'assets/img/brand/logo.svg';
    logo = 'assets/img/brand/logo.svg';
    public navbarBrandFull: any = {src: 'assets/img/brand/logo.png', alt: 'Alvadoo Logo'};
    public navbarBrandMinimized: any = {src: 'assets/img/brand/logo.png', alt: 'Alvadoo Logo'};

    private context: {
        [key: string]: any
    } = {};

    asideContext = {};
    user: UserModel;
    loginTime: Date;
    contextMenu: ContextMenuItem[];
    contextMenuDropdown: ContextMenuItem[];
    contextMenuNormal: ContextMenuItem[];
    actionMenu: NavData;

    sysConfig: any;
    sysConfigId: string;
    private notification = new Subject<any>();
    countryCode: string;
    language: string;
    locale: string;
    selectedSubscriptions: SubscriptionModel[];
    criteria: any[] = [];
    subscriptionCriteria: any[] = [];

    notificationTypes: any[] = [
        {label: 'Email', value: 'EMAIL', help: 'Send Email'},
        {label: 'Letter', value: 'LETTER', help: 'Send Letter'},
        {label: 'Both', value: 'BOTH', help: 'Send Both Email & Letter'}
    ];

    locales: any[] = [
        {label: 'English', value: 'en_US'},
        {label: 'Français', value: 'fr_FR'},
        {label: 'Deutsch', value: 'de_DE'}
    ];

    notificationTypesMap: any = {};

    newSubscriptionProcessModal: ModalDirective;

    currentModuleName: string = 'subscriptions';


    constructor(
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private translationPipe: TranslatorPipe,
        @Inject(LOCALE_ID) public LOCALE: string
        ) {
            this.locale = LOCALE;
            const localeSplit = LOCALE.split('-');
            if (localeSplit.length === 2) {
                this.language = localeSplit[0];
                this.countryCode = localeSplit[1];
            }

            this.notificationTypes.forEach(notificationType => {
                this.notificationTypesMap[notificationType.value] = notificationType;
            });
    }


    setLogo(object: any) {
        if (object.logo && object.logo.trim() !== '') {
            this.logo = object.logo.trim();
            this.navbarBrandFull.src = this.logo;
            this.navbarBrandMinimized.src = this.logo;
        }
    }

    showSuccess(message: any, messageId?: string, params?: any[]): void {
        if (messageId) {
            message = this.translationPipe.transform(messageId);
        }
        if (message.length < 200) {
            this.toastr.success(message);
        }
    }

    showInfo(message: any, messageId?: string, params?: any[]): void {
        if (messageId) {
            message = this.translationPipe.transform(messageId);
        }
        if (message.length < 200) {
            this.toastr.info(message);
        }
    }

    showWarning(message: any, messageId?: string, params?: any[]): void {
        if (messageId) {
            message = this.translationPipe.transform(messageId);
        }
        if (message.length < 200) {
            this.toastr.warning(message);
        }
    }

    showError(error: any, messageId?: string, params?: any[]): void {
        if (messageId) {
            error = this.translationPipe.transform(messageId);
        }
        if (error.error) {
            error = error.error;
        }
        error = error.split('\n');
        if (error instanceof Array) {
            error.forEach(errorItem => {
                let errorCode = errorItem;
                if (errorItem.code) {
                    errorCode = errorItem;
                }
                this._showError(errorCode);
            });
        } else {
            const errorItem = error;
            let errorCode = errorItem;
            if (errorItem.code) {
                errorCode = errorItem;
            }
            this._showError(errorCode);
        }
    }

    set(key: string, value: any) {
        this.context[key] = value;
    }

    get(key: string): any {
        return this.context[key];
    }

    getCopy(key: string): any {
        let value = this.get(key);
        if (value != null) {
            value = this.clone(value);
        }
        return value;
    }

    clone(obj: any) {
        if (obj != null && typeof obj === 'object') {
            obj = JSON.parse(JSON.stringify(obj));
        }
        return obj;
    }

    stopEvents($event: any) {
        if ($event != null) {
            $event.stopPropagation();
            $event.preventDefault();
        }
    }

    registerForNotification(): Observable<any> {
        if (this.notification == null) {
            this.notification = new Subject<any>();
        }
        return this.notification.asObservable();
    }

    unregisterNotification() {
        if (this.notification) {
            this.notification.unsubscribe();
            this.notification = null;
        }
    }

    notify(obj: any) {
        this.notification.next(obj);
    }

    setContextMenu(contextMenu: ContextMenuItem[]): Observable<ContextMenuItem> {
        this.contextMenu = contextMenu;
        this.actionMenu = null;
        if (contextMenu && contextMenu.length > 0) {
            this.actionMenu = {
                name: 'Actions',
                action: true,
                children: []
            };
            contextMenu.forEach(contextMenuItem => {
                if (contextMenuItem.action) {
                    const actionMenuItemChild: NavData = {
                        name: contextMenuItem.label,
                        icon: contextMenuItem.icon,
                        action: true,
                        attributes: {context: contextMenuItem}
                    };
                    this.actionMenu.children.push(actionMenuItemChild);
                }
            });
        }

        return this.registerForNotification();
    }

    setNoContextMenu() {
        this.contextMenu = null;
        this.contextMenuDropdown = null;
        this.contextMenuNormal = null;
    }

    notifyActionMenu(navData: NavData) {
        if (navData.action && navData.attributes && navData.attributes.context) {
            this.notifyContextMenuItem(navData.attributes.context);
        }
    }

    notifyContextMenuItem(contextMenu: ContextMenuItem) {
        this.notify(contextMenu);
    }

    clearContext(): void {
        this.removeLoggedInUser();
        this.removeToken();
        this.removeUserMenuItems();
        this.unregisterNotification();
        this.context = {};
    }

    setLoggedInUser(user: UserModel) {
        this.user = user;
        this.loginTime = new Date();
    }

    getLoggedInUser(): UserModel {
        if (this.user == null) {
            const userResponse = this.getLoginResponse();
            if (userResponse) {
                this.user = UserModel.getInstanceFromResponse(userResponse);
            }
        }
        return this.user;
    }

    isUserLoggedIn(): boolean {
        return (this.user != null);
    }

    removeLoggedInUser(): void {
        this.user = null;
        this.setContextMenu(null);
        this.setLoginResponse(null);
    }

    setToken(token: string): void {
        this.set('REMEMBER_ME_TOKEN', token);
    }

    getToken(): string {
        let token = this.get('REMEMBER_ME_TOKEN');
        if (token == null) {
            const user = this.getLoginResponse();
            if (user) {
                token = user.session_id;
            }
        }
        return token;
    }

    removeToken(): void {
        this.set('REMEMBER_ME_TOKEN', null);
    }

    setUserMenuItems(navData: NavData[]): void {
        this.set('USER_MENU', navData);
    }

    getUserMenuItems(includeActions = true): NavData[] {
        let navData: NavData[] =  this.get('USER_MENU');
        if (navData == null) {
            navData = [];
        }
        const index = navData.findIndex(x => x.action);
        if (index >= 0) {
            navData.splice(index, 1);
        }

        if (includeActions && this.actionMenu) {
            navData.push(this.actionMenu);
        }
        return navData;
    }

    removeUserMenuItems(): void {
        this.set('USER_MENU', null);
    }

    setLoginResponse(response: any) {
        if (response == null) {
            localStorage.removeItem('LOGIN_RESPONSE');
        } else {
            localStorage.setItem('LOGIN_RESPONSE', JSON.stringify(response));
        }
    }

    getLoginResponse(): any {
        const response = localStorage.getItem('LOGIN_RESPONSE');
        if (response) {
            return JSON.parse(response);
        } else {
            return null;
        }
    }

    enableRememberMe() {
        localStorage.setItem('REMEMBER_ME', 'true');
    }

    disableRememberMe() {
        localStorage.removeItem('REMEMBER_ME');
    }

    isRememberMeEnabled(): boolean {
        const rememberMe = localStorage.getItem('REMEMBER_ME');
        return rememberMe === 'true';
    }

    camelCase(str: string) {
        if (str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            }).replace(/\s+/g, '');
        } else {
            return '';
        }
    }

    showCustomError(error: any, messageId?: string): void {
        if (messageId) {
            error = this.translationPipe.transform(messageId);
        }
        if (error.error) {
            error = error.error;
        }
        if (error instanceof Array) {
            error.forEach(errorItem => {
                let errorCode = errorItem;
                if (errorItem.code) {
                    errorCode = errorItem;
                }
                this._showError(errorCode);
            });
        } else {
            const errorItem = error;
            let errorCode = errorItem;
            if (errorItem.code) {
                errorCode = errorItem;
            }
            this._showError(errorCode);
        }
    }

    private _showError(errorCode: any, params?: any[]) {
        if (errorCode.code) {
            params = errorCode.args;
            errorCode = errorCode.code;
        }
        let errorMessage = errorMessages[errorCode];
        if (errorMessage == null) {
            errorMessage = errorCode;
        }
        if (errorMessage.length < 200) {
            this.toastr.error(errorMessage);
        }
    }

    stopPropagation($event: any) {
        if ($event) {
            if ($event.stopPropagation) {
                $event.stopPropagation();
            }
            if ($event.preventDefault) {
                $event.preventDefault();
            }
        }
    }

    newGuid(): string {
        return Guid.raw();
    }

    isLike(value1: string, value2: string) {
        if (this.isNotEmpty(value2)) {
            if (this.isNotEmpty(value1)) {
                if (typeof value1 === 'string') {
                    value1 = value1.toLowerCase();
                }
                if (typeof value2 === 'string') {
                    value2 = value2.toLowerCase();
                }
                return (value1.indexOf(value2) >= 0);
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    isEmpty(value: any): boolean {
        let empty = false;
        if (value == null) {
            empty = true;
        } else if (value instanceof Array) {
            empty = value.length === 0;
        } else if (value instanceof Date) {
            empty = false;
        } else if (typeof value === 'object') {
            empty = Object.keys(value).length === 0;
        } else {
            empty = (value + '').trim() === '';
        }
        return empty;
    }

    isAllEmpty(values: any[]) {
        let empty = true;
        if (values) {
            values.forEach(value => {
                if (this.isNotEmpty(value)) {
                    empty = false;
                }
            });
        }
        return empty;
    }

    isNotEmpty(value: any): boolean {
        return !this.isEmpty(value);
    }

    getDateAsString(date: Date): string {
        let dateString = null;
        if (date) {
            if (date instanceof Date) {
                dateString = this.datePipe.transform(date, 'yyyy-MM-dd');
            } else {
                dateString = date + '';
            }
        }
        return dateString;
    }

    getDateTimeAsString(date: Date): string {
        let dateString = null;
        if (date) {
            dateString = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
        }
        return dateString;
    }

    getDateFromString(str: string): Date {
        let date: Date = null;
        if (this.isNotEmpty(str)) {
            try {
                date = new Date(str);
            } catch (e) {
                // ignore
            }
        }
        return date;
    }

    isCancelled(subscription: SubscriptionModel) {
        return subscription.statuses && subscription.statuses.indexOf('CANCELLED') >= 0;
    }

    isReactivated(subscription: SubscriptionModel) {
        return subscription.statuses && subscription.statuses.indexOf('REACTIVATED') >= 0;
    }

    isEnded(subscription: SubscriptionModel) {
        return subscription.endDate && subscription.endDate.getTime() < (new Date().getTime());
    }

}

export interface ContextMenuItem {
    name: string;
    icon: string;
    label: string;
    tooltip?: string;
    placement?: string;
    readonly?: boolean;
    bold?: boolean;
    dropdown?: boolean;
    config?: any;
    action?: boolean;
}
