/**
 * Translator pipe responsbile for traslating the given string to the current locale
 */

// Angular
import { PipeTransform, Pipe } from '@angular/core';
import { GLOBAL } from '../app.module';


@Pipe({
    name: 'translate'
})
export class TranslatorPipe implements PipeTransform {

    constructor() {
       
    }
    transform(message: any, params?: any) {

        message = this.get(message);
        if (message && params && params instanceof Array) {
            params.forEach((param, index) => {
                message = message.replaceAll('{' + index + '}', param)
            })
        }

        return message;

    }

    get(messageId: string, defaultMessage?: string): string {
        let message = "";
        const translations = GLOBAL['translations'];
        let locale = GLOBAL['locale']
        if (locale == null) {
            locale = 'en';
        }
        if (translations[locale]) {
            message = translations[locale][messageId]
            if (message == null ) {
                if (defaultMessage  != null) {
                    message = defaultMessage;
                } else {
                    message = messageId;
                }
            }
        }
        return message;
    }

}
