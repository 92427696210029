import { Pipe, PipeTransform } from '@angular/core';
import { PaymentRegistryModel } from '../models/payment-registry.model';
import { SubscriptionModel } from '../models/subscription.model';

@Pipe({
    name: 'payments'
})
export class PaymentsFilter  implements PipeTransform {
    transform(payments: PaymentRegistryModel[], subscriptions: SubscriptionModel[]) {
        const filtered: PaymentRegistryModel[] = [];
        if (payments != null && payments.length > 0) {
            payments.forEach(payment => {
                if (subscriptions && subscriptions.length > 0) {
                    for (let s = 0; s < subscriptions.length; s++) {
                        const subscription = subscriptions[s];
                        if (payment.subscription && subscription.id === payment.subscription.id) {
                            filtered.push(payment);
                            break;
                        }
                    }
                } else {
                    filtered.push(payment);
                }
            });
        }
        return filtered;
    }
}
