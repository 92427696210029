import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {

  }
  transform(value) {
    if (value) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    } else {
      return '';
    }
  }
}
