/**
 * Super class for all the model
 */

export class BaseModel {

    public static getDate(input: any): Date {
        let date: Date = null;
        if (input) {
            if (input instanceof Date) {
                date = input;
            } else {
                date = new Date(input);
            }
        }

        return date;
    }

    public static getValue(input: any): any {
        if (input) {
            return input;
        } else {
            return null;
        }
    }

    public static getNumber(input: any): any {
        if (input !== false) {
            return input;
        } else {
            return null;
        }
    }

}
