import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { AppContext } from '../../app.context';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-auto-login',
    templateUrl: './auto-login.component.html',
    styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {

    constructor(private service: LoginService,
        private context: AppContext,
        private router: Router,
        private route: ActivatedRoute) {

    }

    ngOnInit() {
      if (this.context.getToken()) {
        this.service.autoLogin().subscribe(response => {
          this.performPostLogin(response);
        }, error => {
          this.context.disableRememberMe();
          this.router.navigate(['login']);
        });
      } else {
        this.router.navigate(['login']);
      }
    }

    performPostLogin(response: any) {
          if (response != null && response.session_id != null) {
            // ensureing that the datapoints loaded
            let url = this.context.get('auto-login-url');
            if (url == null) {
                url = 'home';
            }
            this.router.navigate([url]);
          } else {
            // Authentication failed
            this.context.disableRememberMe();
            this.router.navigate(['login']);
          }
      }
}
