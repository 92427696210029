/**
 * Provides the https get, post, put and delete methods
 */

// Angular
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// 3rd Party
import { Observable } from 'rxjs';

// Application Specific
import { IService } from './service.interface';
import { AppContext } from '../app.context';

@Injectable()
export abstract class HttpBaseService implements IService {
    httpClient: HttpClient;
    options: any;
    context: AppContext;
    constructor(injector: Injector) {
        this.httpClient = injector.get(HttpClient);
        this.context = injector.get(AppContext);
    }
    _get(url: string, data?: any): Observable<any> {
        this.options = this._getOptions();
        url = this.getFullUrl(url);
        this.options.params = data;
        return this.httpClient.get(url, this.options);
    }

    _post(url: string, data: any): Observable<any> {
        this.options = this._getOptions();
        url = this.getFullUrl(url);
        return this.httpClient.post(url, data, this.options);
    }

    _put(url: string, data: any): Observable<any> {
        this.options = this._getOptions();
        url = this.getFullUrl(url);
        return this.httpClient.put(url, data, this.options);
    }

    _delete(url: string, data?: any): Observable<any> {
        this.options = this._getOptions();
        url = this.getFullUrl(url);
        this.options.params = data;
        return this.httpClient.delete(url, this.options);
    }

    getFullUrl(url: string): string {
        if (url.startsWith('http')) {
            return url;
        } else {
            return this.getUrl(url);
        }
    }

    abstract getUrl(url: string): string;

    _getOptions() {
        const options = {};
        return options;
    }
}
