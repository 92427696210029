import { BaseModel } from './base.model';


export class SubscriptionProcessModel extends BaseModel {
    id: number;
    name: string;
    periodStartDate: Date;
    processStartDatetime: Date;
    total: number;
    successCount: number;
    errorCount: number;
    skippedCount: number;
    status: string;
    input: any;

    public static getInstanceFromResponse(response: any): SubscriptionProcessModel {
        const model = new SubscriptionProcessModel();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.periodStartDate = BaseModel.getDate(response.periodStartDate);
        model.processStartDatetime = BaseModel.getDate(response.processStartDatetime);
        model.total = BaseModel.getNumber(response.total);
        model.successCount = BaseModel.getNumber(response.successCount);
        model.errorCount = BaseModel.getNumber(response.errorCount);
        model.skippedCount = BaseModel.getNumber(response.skippedCount);
        if (model.total) {
            if (!model.successCount) {
                model.successCount = 0;
            }
            if (!model.errorCount) {
                model.errorCount = 0;
            }
            if (!model.skippedCount) {
                model.skippedCount = 0;
            }
        }
        model.status = BaseModel.getValue(response.status);
        model.input = BaseModel.getValue(response.input);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionProcessModel[] {
        const models: SubscriptionProcessModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: SubscriptionProcessModel = SubscriptionProcessModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export class SubscriptionTemplateModel extends BaseModel {
    id: number;
    name: string;
    description: string;
    subcriptionType: string;
    prefix: string;
    numbering: string;
    periodDuration: number;
    recurringInterval: number;
    minimumTerm: number;
    leadTime: number;
    leadTimeType: string;
    cancelPeriod: number;
    cancelPeriodType: string;
    cancellationNoticePeriod: number;
    cancellationNoticePeriodType: string;
    revocationPeriod: number;
    revocationPeriodType: string;
    renewalIntervals: number;
    billingFrequency: number;
    invoiceMethod: string;
    debitLimit: number;
    settlementMethod: string;
    standardDebitDate: Date;
    lastDebitDate: Date;
    paymentMethod: string;
    public static getInstanceFromResponse(response: any): SubscriptionTemplateModel {
        const model = new SubscriptionTemplateModel();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.description = BaseModel.getValue(response.description);
        model.subcriptionType = BaseModel.getValue(response.subcriptionType);
        model.prefix = BaseModel.getValue(response.prefix);
        model.numbering = BaseModel.getValue(response.numbering);
        model.periodDuration = BaseModel.getValue(response.periodDuration);
        model.recurringInterval = BaseModel.getValue(response.recurringInterval);
        model.minimumTerm = BaseModel.getValue(response.minimumTerm);
        model.leadTime = BaseModel.getValue(response.leadTime);
        model.leadTimeType = BaseModel.getValue(response.leadTimeType);
        model.cancelPeriod = BaseModel.getValue(response.cancelPeriod);
        model.cancelPeriodType = BaseModel.getValue(response.cancelPeriodType);
        model.cancellationNoticePeriod = BaseModel.getValue(response.cancellationNoticePeriod);
        model.cancellationNoticePeriodType = BaseModel.getValue(response.cancellationNoticePeriodType);
        model.revocationPeriod = BaseModel.getValue(response.revocationPeriod);
        model.revocationPeriodType = BaseModel.getValue(response.revocationPeriodType);
        model.renewalIntervals = BaseModel.getValue(response.renewalIntervals);
        model.billingFrequency = BaseModel.getValue(response.billingFrequency);
        model.invoiceMethod = BaseModel.getValue(response.invoiceMethod);
        model.debitLimit = BaseModel.getValue(response.debitLimit);
        model.settlementMethod = BaseModel.getValue(response.settlementMethod);
        model.standardDebitDate = BaseModel.getDate(response.standardDebitDate);
        model.lastDebitDate = BaseModel.getDate(response.lastDebitDate);
        model.paymentMethod = BaseModel.getValue(response.paymentMethod);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionTemplateModel[] {
        const models: SubscriptionTemplateModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: SubscriptionTemplateModel = SubscriptionTemplateModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export class SubscriptionTemplatePeriodModel extends BaseModel {
    id: number;
    templateId: number;
    periodStart: Date;
    periodEnd: Date;

    public static getInstanceFromResponse(response: any): SubscriptionTemplatePeriodModel {
        const model = new SubscriptionTemplatePeriodModel();
        model.id = BaseModel.getNumber(response.id);
        model.templateId = BaseModel.getNumber(response.templateId);
        model.periodStart = BaseModel.getDate(response.periodStart);
        model.periodEnd = BaseModel.getDate(response.periodEnd);

        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionTemplatePeriodModel[] {
        const models: SubscriptionTemplatePeriodModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const model: SubscriptionTemplatePeriodModel = SubscriptionTemplatePeriodModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export class SubscriptionTemplateProcessModel extends BaseModel {
    id: number;
    templateId: number;
    name: string;
    functionName: string;
    productId: number;
    productCode: string;
    productName: string;
    stages: SubscriptionTemplateProcessStageModel[];
    public static getInstanceFromResponse(response: any): SubscriptionTemplateProcessModel {
        const model = new SubscriptionTemplateProcessModel();
        model.id = BaseModel.getNumber(response.id);
        model.templateId = BaseModel.getNumber(response.templateId);
        model.name = BaseModel.getValue(response.name);
        model.functionName = BaseModel.getValue(response.functionName);
        model.productId = BaseModel.getValue(response.productId);
        model.productCode = BaseModel.getValue(response.productCode);
        model.productName = BaseModel.getValue(response.productName);
        model.stages = SubscriptionTemplateProcessStageModel.getInstancesFromResponse(response.stages);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionTemplateProcessModel[] {
        const models: SubscriptionTemplateProcessModel[] = [];
        if (responses) {
            responses.forEach(response => {
                // tslint:disable-next-line:max-line-length
                const model: SubscriptionTemplateProcessModel = SubscriptionTemplateProcessModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export class SubscriptionTemplateProcessStageModel extends BaseModel {
    id: number;
    name: string;
    functionName: string;
    commitAfterException: boolean;
    continueOnException: boolean;
    lifeCycle: string;

    public static getInstanceFromResponse(response: any): SubscriptionTemplateProcessStageModel {
        const model = new SubscriptionTemplateProcessStageModel();
        model.id = BaseModel.getNumber(response.id);
        model.name = BaseModel.getValue(response.name);
        model.functionName = BaseModel.getValue(response.functionName);
        model.commitAfterException = response.commitAfterException;
        model.continueOnException = response.continueOnException;
        model.lifeCycle = BaseModel.getValue(response.lifeCycle);

        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionTemplateProcessStageModel[] {
        const models: SubscriptionTemplateProcessStageModel[] = [];
        if (responses) {
            responses.forEach(response => {
                // tslint:disable-next-line:max-line-length
                const model: SubscriptionTemplateProcessStageModel = SubscriptionTemplateProcessStageModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }
}

export interface NewProcessRequest {
    template_id: number;
    period_start_date: Date;
    process_id: number;
    process_stage_ids: number[];
}


export class SubscriptionProcessDetailModel extends BaseModel {
    id: number;
    batchId: number;
    processId: number;
    subscriptionId: number;
    subscriptionReferenceId: string;
    status: string;
    objectId: string;
    objectType: string;
    invoiceId: number;
    invoiceNumber: string;
    processLastExecutedOn: Date;
    data: SubscriptionProcessDetailData;
    errors: any;

    public static getInstanceFromResponse(response: any): SubscriptionProcessDetailModel {
        const model = new SubscriptionProcessDetailModel();
        model.id = BaseModel.getNumber(response.id);
        model.batchId = BaseModel.getNumber(response.batchId);
        model.processId = BaseModel.getNumber(response.processId);
        model.subscriptionId = BaseModel.getNumber(response.subscriptionId);
        model.subscriptionReferenceId = BaseModel.getValue(response.subscriptionReferenceId);
        model.status = BaseModel.getValue(response.status);
        model.objectId = BaseModel.getNumber(response.objectId);
        model.objectType = BaseModel.getValue(response.objectType);
        model.invoiceId = BaseModel.getNumber(response.invoiceId);
        model.invoiceNumber = BaseModel.getValue(response.invoiceNumber);
        model.processLastExecutedOn = BaseModel.getDate(response.processLastExecutedOn);
        model.data = BaseModel.getValue(response.data) as SubscriptionProcessDetailData;
        model.errors = BaseModel.getValue(response.errors);
        SubscriptionProcessDetailModel.populateObjectDisplayNames(model.data);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): SubscriptionProcessDetailModel[] {
        const models: SubscriptionProcessDetailModel[] = [];
        if (responses) {
            responses.forEach(response => {
                // tslint:disable-next-line:max-line-length
                const model: SubscriptionProcessDetailModel = SubscriptionProcessDetailModel.getInstanceFromResponse(response);
                models.push(model);
            });
        }
        return models;
    }

    public static populateObjectDisplayNames(data: any) {
        if (data) {
            if (data.input) {
                SubscriptionProcessDetailModel.setObjectDisplayName(data.input);
            }
            if (data.output) {
                data.output.forEach(output => {
                    SubscriptionProcessDetailModel.setObjectDisplayName(output);
                });
            }
        }
    }

    public static setObjectDisplayName(object: any) {
        if (object) {
            if (object.type === 'alvadoo.subscription.subscription') {
                object.displayName = `Subscription: ${object.ref}`;
            } else if (object.type === 'account.invoice') {
                object.displayName = `Invoice: ${object.ref}`;
            } else if (object.type === 'alvadoo.lotto.participation') {
                object.displayName = `Lotto Participation: ${object.ref}`;
            } else if (object.type === 'account.invoice.credit_note') {
                object.displayName = `Crdit Note: ${object.ref}`;
            } else if (object.type === 'alvadoo.payment.exchange_registry') {
                object.displayName = `Payment: ${object.ref}`;
            } else if (object.type === 'alvadoo.io.export') {
                object.displayName = `Export: ${object.ref}`;
            } else if (object.type === 'alvadoo.subscription.subscription.process.detail') {
                object.displayName = `Data: ${object.ref}`;
            } else if (object.type === 'alvadoo.communication.communication') {
                object.displayName = `Communication: ${object.ref}`;
            }
        }

    }
}

export interface SubscriptionProcessDetailData {
    input: SubscriptionProcessDetailDataInput;
    output: SubscriptionProcessDetailDataOutput[];
}

export interface SubscriptionProcessDetailDataInput {
    id: number;
    ref: string;
    type: string;
    displayName: string;
}

export interface SubscriptionProcessDetailDataOutput {
    id: number;
    ref: string;
    type: string;
    displayName: string;
}
