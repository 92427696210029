import { Component, ComponentFactoryResolver } from '@angular/core';
import { UiBaseComponent } from '../ui-base.component';

@Component({
    selector: 'app-ui-text-area',
    templateUrl: 'ui-text-area.component.html',
    styleUrls: ['../ui-base.component.scss', 'ui-text-area.component.scss']
})
export class UiTextAreaComponent extends UiBaseComponent {

}
