import { BaseModel } from './base.model';

export class ContactInfoModel extends BaseModel {
    type: string;
    contact: string;

    public static getInstanceFromResponse(response: any): ContactInfoModel {
        const contactInfo: ContactInfoModel = new ContactInfoModel();
        contactInfo.type = BaseModel.getValue(response.type);
        contactInfo.contact = BaseModel.getValue(response.contact);
        return contactInfo;
    }

    public static getInstancesFromResponse(responses: any[]): ContactInfoModel[] {
        const contactInfos: ContactInfoModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const contactInfo: ContactInfoModel = ContactInfoModel.getInstanceFromResponse(response);
                contactInfos.push(contactInfo);
            });
        }
        return contactInfos;
    }

    public static clone(model: ContactInfoModel): ContactInfoModel {
        const cloned = new ContactInfoModel();
        cloned.type = model.type;
        cloned.contact = model.contact;
        return cloned;
    }

}


