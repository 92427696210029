import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { ContactInfoModel } from './contact-info.model';
import { PaymentMethodsModel } from './payment-methods.model';

export class ContactModel extends BaseModel {
    id: number;
    referenceId: string;
    type: string;
    isComapny: string;
    salutation: string;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth: Date;
    addresses: AddressModel[];
    contactInfos: ContactInfoModel[];
    paymentMethods: PaymentMethodsModel[];
    active: boolean;
    blocked: boolean;
    blockedMessage: string;
    notificationType: string;
    externalId: string;
    agentId: string;
    saleTeamId: number;
    saleTeamName: string;
    createDate: Date;

    preferredAddress: AddressModel;
    phone: string;
    mobile: string;
    email: string;
    changed: boolean;

    public static getNewInstance(): ContactModel {
        const model = new ContactModel();
        const address = AddressModel.getNewInstance(true);
        model.addresses = [address];
        model.preferredAddress = address;
        return model;
    }

    public static getInstanceFromResponse(response: any): ContactModel {
        const contact: ContactModel = new ContactModel();
        contact.id = BaseModel.getValue(response.id);
        contact.referenceId = BaseModel.getValue(response.referenceId);
        contact.type = BaseModel.getValue(response.type);
        contact.isComapny = response.isComapny;
        contact.salutation = BaseModel.getValue(response.salutation);
        contact.firstName = BaseModel.getValue(response.firstName);
        contact.lastName = BaseModel.getValue(response.lastName);
        contact.fullName = BaseModel.getValue(response.fullName);
        contact.dateOfBirth = BaseModel.getDate(response.dateOfBirth);
        contact.addresses = AddressModel.getInstancesFromResponse(response.addresses);
        if (contact.addresses && contact.addresses.length > 0) {
            contact.preferredAddress = contact.addresses[0];
            contact.addresses.forEach(address => {
                if (address.preferred) {
                    contact.preferredAddress = address;
                }
            });
        }
        contact.contactInfos = ContactInfoModel.getInstancesFromResponse(response.contactInfos);
        if (contact.contactInfos) {
            contact.contactInfos.forEach(contactInfo => {
                if (contactInfo.type === 'Email') {
                    contact.email = BaseModel.getValue(contactInfo.contact);
                } else if (contactInfo.type === 'Phone') {
                    contact.phone = BaseModel.getValue(contactInfo.contact);
                } else if (contactInfo.type === 'Mobile') {
                    contact.mobile = BaseModel.getValue(contactInfo.contact);
                }
            });
        }
        contact.paymentMethods = PaymentMethodsModel.getInstancesFromResponse(response.paymentMethods);
        contact.active = response.active;
        contact.blocked = response.blocked;
        contact.blockedMessage = BaseModel.getValue(response.blockedMessage);
        contact.notificationType = BaseModel.getValue(response.notificationType);
        contact.externalId = BaseModel.getValue(response.externalId);
        contact.agentId = BaseModel.getValue(response.agentId);
        contact.saleTeamId = BaseModel.getValue(response.saleTeamId);
        contact.saleTeamName = BaseModel.getValue(response.saleTeamName);
        contact.createDate = BaseModel.getDate(response.createDate);


        return contact;
    }

    public static getInstancesFromResponse(responses: any): ContactModel[] {
        const contacts: ContactModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const contact: ContactModel = ContactModel.getInstanceFromResponse(response);
                contacts.push(contact);
            });
        }
        return contacts;
    }

    public static clone(model: ContactModel) {
        const cloned = new ContactModel();
        cloned.id = model.id;
        cloned.referenceId = model.referenceId;
        cloned.type = model.type;
        cloned.isComapny = model.isComapny;
        cloned.salutation = model.salutation;
        cloned.firstName = model.firstName;
        cloned.lastName = model.lastName;
        cloned.fullName = model.fullName;
        if (model.dateOfBirth) {
            cloned.dateOfBirth = new Date(model.dateOfBirth.getTime());
        }
        if (model.addresses) {
            cloned.addresses = [];
            model.addresses.forEach(address => {
                cloned.addresses.push(AddressModel.clone(address));
            });
        }

        if (model.preferredAddress) {
            cloned.preferredAddress = AddressModel.clone(model.preferredAddress);
        }

        if (model.contactInfos) {
            cloned.contactInfos = [];
            model.contactInfos.forEach(contactInfo => {
                cloned.contactInfos.push(ContactInfoModel.clone(contactInfo));
            });
        }

        cloned.phone = model.phone;
        cloned.mobile = model.mobile;
        cloned.email = model.email;

        if (model.paymentMethods) {
            cloned.paymentMethods = [];
            model.paymentMethods.forEach(paymentMethod => {
                cloned.paymentMethods.push(PaymentMethodsModel.clone(paymentMethod));
            });
        }

        cloned.active = model.active;
        cloned.blocked = model.blocked;
        cloned.blockedMessage = model.blockedMessage;
        cloned.notificationType = model.notificationType;
        cloned.externalId = model.externalId;
        cloned.agentId = model.agentId;
        cloned.saleTeamId = model.saleTeamId;
        cloned.saleTeamName = model.saleTeamName;
        cloned.createDate = model.createDate;
        return cloned;
    }

    public static getRequest(model: ContactModel) {
        const request: any = {};
        request.id = model.id;
        request.referenceId = model.referenceId;
        request.type = model.type;
        request.isComapny = model.isComapny;
        request.salutation = model.salutation;
        request.firstName = model.firstName;
        request.lastName = model.lastName;
        request.fullName = model.fullName;
        if (model.dateOfBirth) {
            const dateOfBirth = new Date(model.dateOfBirth.getTime());
            request.dateOfBirth = dateOfBirth.toISOString().substring(0, 10);
        }
        if (model.addresses) {
            request.addresses = [];
            model.addresses.forEach(address => {
                request.addresses.push(AddressModel.clone(address));
            });
        }

        if (model.preferredAddress) {
            request.preferredAddress = AddressModel.clone(model.preferredAddress);
        }

        if (model.contactInfos) {
            request.contactInfos = [];
            model.contactInfos.forEach(contactInfo => {
                request.contactInfos.push(ContactInfoModel.clone(contactInfo));
            });
        }

        request.phone = model.phone;
        request.mobile = model.mobile;
        request.email = model.email;

        if (model.paymentMethods) {
            request.paymentMethods = [];
            model.paymentMethods.forEach(paymentMethod => {
                request.paymentMethods.push(PaymentMethodsModel.clone(paymentMethod));
            });
        }

        request.active = model.active;
        request.blocked = model.blocked;
        request.blockedMessage = model.blockedMessage;
        request.notificationType = model.notificationType;
        request.externalId = model.externalId;
        request.agentId = model.agentId;
        request.teamId = BaseModel.getValue(model.saleTeamId);


        return request;
    }
}
