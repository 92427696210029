import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { OrderLineModel } from './order-line.model';
import { CurrencyModel } from './product.model';
import { UserModel } from './user.model';


export class OrderModel extends BaseModel {
    id: number;
    partnerId: string;
    dateOrder: Date;
    name: string;
    reference: string;
    clientOrderRef: string;
    requirePayment: boolean;
    requireSignature: boolean;
    currencyRate: number;
    amountUntaxed: number;
    amountTax: number;
    amountTotal: number;
    validityDate: Date;
    isExpired: boolean;
    state: string;
    invoiceAddress: AddressModel;
    deliveryAddress: AddressModel;
    user: UserModel;
    currency: CurrencyModel;
    orderLines: OrderLineModel[];
    invoiceNo: string;
    amountDue: number;
    paymentType: string;

    public static getInstanceFromResponse(response: any): OrderModel {
        const model: OrderModel = new OrderModel();
        model.id = BaseModel.getValue(response.id);
        model.name = BaseModel.getValue(response.name);
        model.partnerId = BaseModel.getValue(response.partnerId);
        model.dateOrder = BaseModel.getDate(response.dateOrder);
        model.clientOrderRef = BaseModel.getValue(response.clientOrderRef);
        model.requirePayment = response.requirePayment;
        model.requireSignature = response.requireSignature;
        model.currencyRate = BaseModel.getValue(response.currencyRate);
        model.amountUntaxed = BaseModel.getValue(response.amountUntaxed);
        model.amountTax = BaseModel.getValue(response.amountTax);
        model.amountTotal = BaseModel.getValue(response.amountTotal);
        model.validityDate = BaseModel.getDate(response.validityDate);
        model.isExpired = response.isExpired;
        model.state = BaseModel.getValue(response.state);
        model.invoiceAddress = AddressModel.getInstanceFromResponse(response.invoiceAddress);
        model.deliveryAddress = AddressModel.getInstanceFromResponse(response.deliveryAddress);
        model.user = UserModel.getInstanceFromResponse(response.user);
        model.currency = CurrencyModel.getInstanceFromDepenentResponse(response);
        model.orderLines = OrderLineModel.getInstancesFromResponse(response.orderLines);
        return model;
    }

    public static getInstancesFromResponse(responses: any): OrderModel[] {
        const models: OrderModel[] = [];
        if (responses) {
            responses.forEach((response, index) => {
                const model: OrderModel = OrderModel.getInstanceFromResponse(response);
                if (index > 5) {
                    model.amountDue = 0;
                    model.paymentType = 'Invoice';
                } else {
                    model.amountDue = model.amountTotal;
                }
                model.invoiceNo = 'INV/2021/' + model.name.substr(2);
                models.push(model);
            });
        }
        return models;
    }
}
