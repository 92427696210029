import { Component, Input, OnChanges } from '@angular/core';
import { UiTable } from './ui-renderer.interfaces';

@Component({
    selector: 'app-ui-renderer',
    templateUrl: 'ui-renderer.component.html',
    styleUrls: ['ui-renderer.component.scss']
})
export class UiRendererComponent implements OnChanges {
    // tslint:disable-next-line:no-input-rename
    @Input('meta') meta: UiTable;
    // tslint:disable-next-line:no-input-rename
    @Input('data') data: any;

    ngOnChanges() {

    }
}
