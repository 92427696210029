import { BaseModel } from './base.model';

export class AuditLogModel extends BaseModel {
    id: number;
    name: string;
    userId: number;
    userName: string;
    partnerId: number;
    partnerName: string;
    subscriptionId: number;
    subscriptionName: string;
    subscriptionReferenceId: string;
    description: string;
    messageId: string;
    parameters: any;
    createDate: Date;
    lines: AuditLogLineModel[];

    public static getInstanceFromResponse(response: any): AuditLogModel {
        const model: AuditLogModel = new AuditLogModel();
        model.id = response.id;
        model.name = BaseModel.getValue(response.name);
        model.userId = BaseModel.getValue(response.userId);
        model.userName = BaseModel.getValue(response.userName);
        model.partnerId = BaseModel.getValue(response.partnerId);
        model.partnerName = BaseModel.getValue(response.partnerName);
        model.subscriptionId = BaseModel.getValue(response.subscriptionId);
        model.subscriptionName = BaseModel.getValue(response.subscriptionName);
        model.subscriptionReferenceId = BaseModel.getValue(response.subscriptionReferenceId);
        model.description = BaseModel.getValue(response.description);
        model.messageId = BaseModel.getValue(response.messageId);
        if (response.parameters) {
            try {
                response.parameters = response.parameters.replaceAll("'", '"');
                model.parameters = JSON.parse(response.parameters);
            } catch(ex) {
                //IGNORE
            }
        }

        model.createDate = BaseModel.getDate(response.createDate);
        return model;
    }

    public static getInstancesFromResponse(responses: any[]): AuditLogModel[] {
        const models: AuditLogModel[] = [];
        if (responses) {
            responses.forEach(response => {
                const auditlog: AuditLogModel = AuditLogModel.getInstanceFromResponse(response);
                models.push(auditlog);
            });
        }
        return models;
    }
}


export class AuditLogLineModel extends BaseModel {
    id: number;
    fieldId: number;
    fieldName: string;
    fieldDescription: string;
    oldValueText: string;
    newValueText: string;
    public static getInstanceFromResponse(response: any): AuditLogLineModel {
        const model: AuditLogLineModel = new AuditLogLineModel();
        model.id = response.id;
        model.fieldId = BaseModel.getValue(response.fieldId);
        model.fieldName = BaseModel.getValue(response.fieldName);
        model.fieldDescription = BaseModel.getValue(response.fieldDescription);
        model.oldValueText = BaseModel.getValue(response.oldValueText);
        model.oldValueText = AuditLogLineModel.getFormattedValue(model.oldValueText);
        model.newValueText = BaseModel.getValue(response.newValueText);
        model.newValueText = AuditLogLineModel.getFormattedValue(model.newValueText);
        return model;
    }

    public static getFormattedValue(value: string) {
        if (value && value.startsWith("(") && value.endsWith(")")) {
            value = value.substring(value.indexOf("'")+1);
            value = value.substring(0, value.length-2);
        }
        return value;
    }

    public static getInstancesFromResponse(responses: any[]): AuditLogLineModel[] {
        const models: AuditLogLineModel[] = [];
        if (responses) {
            responses.forEach(response => {
                if (response.fieldName && !response.fieldName.endsWith('_ids')) {
                    const auditlog: AuditLogLineModel = AuditLogLineModel.getInstanceFromResponse(response);
                    models.push(auditlog);
                }
            });
        }
        return models;
    }
}