export const TRANSACTIONS = [];

TRANSACTIONS.push({
    transactionId: 'T0001',
    transactionDate: '2016-12-31',
    transactionType: 'INV',
    subscriptionId: 'S0001',
    contactId: 'C0001',
    periodId: '01.01.2017',
    currency: 'EUR',
    amount: -53.15,
    status: 'UNPAID',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2016-11-31',

});


TRANSACTIONS.push({
    transactionId: 'T0002',
    transactionDate: '2017-01-08',
    transactionType: 'PMT',
    subscriptionId: 'S0001',
    contactId: 'C0001',
    periodId: '01.01.2017',
    currency: 'EUR',
    amount: 53.15,
    status: 'PAID',
    paymentMethod: 'SEPA',
    paymentAccount: 'DE68500105175574373437',
    accountingType: 'INV',
    accountingDate: '2016-12-31',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-01-06',
});

TRANSACTIONS.push({
    transactionId: 'T0003',
    transactionDate: '2017-02-09',
    transactionType: 'INV',
    subscriptionId: 'S0001',
    contactId: 'C0001',
    periodId: '03.09.2017',
    currency: 'EUR',
    amount: -53.15,
    status: 'UNPAID',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-02-07',

});

TRANSACTIONS.push({
    transactionId: 'T0004',
    transactionDate: '2017-02.11',
    transactionType: 'PMT',
    subscriptionId: 'S0001',
    contactId: 'C0001',
    periodId: '03.09.2017',
    currency: 'EUR',
    amount: 30.15,
    status: 'PAID',
    paymentMethod: 'SEPA',
    paymentAccount: 'DE68500105175574373437',
    accountingType: 'INV',
    accountingDate: '2017-02-09',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-02-08',
});

TRANSACTIONS.push({
    transactionId: 'T0005',
    transactionDate: '2017-02-22',
    transactionType: 'PMT',
    subscriptionId: 'S0001',
    contactId: 'C0001',
    periodId: '03.09.2017',
    currency: 'EUR',
    amount: 23.00,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '4556370388043041',
    accountingType: 'INV',
    accountingDate: '2017-02-09',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-02-20',
});

TRANSACTIONS.push({
    transactionId: 'T0006',
    transactionDate: '2017-03-03',
    transactionType: 'INV',
    subscriptionId: 'S0002',
    contactId: 'C0002',
    periodId: '04.03.2017',
    currency: 'EUR',
    amount: 28.50,
    status: 'UNPAID',
    owner: 'CSA',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-03-09',

});


TRANSACTIONS.push({
    transactionId: 'T0007',
    transactionDate: '2017-03-05',
    transactionType: 'PMT',
    subscriptionId: 'S0002',
    contactId: 'C0002',
    periodId: '04.03.2017',
    currency: 'EUR',
    amount: 28.50,
    status: 'PAID',
    paymentMethod: 'SEPA',
    paymentAccount: 'DE44500105179379967982',
    accountingType: 'INV',
    accountingDate: '2017-03-03',
    owner: 'CSA',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-03-04',

});


TRANSACTIONS.push({
    transactionId: 'T0008',
    transactionDate: '2017-03-28',
    transactionType: 'INV',
    subscriptionId: 'S0002',
    contactId: 'COOO2',
    periodId: '04.28.2017',
    currency: 'EUR',
    amount: 28.50,
    status: 'UNPAID',
    owner: '',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-03-17',

});


TRANSACTIONS.push({
    transactionId: 'T0009',
    transactionDate: '2017-03-30',
    transactionType: 'PMT',
    subscriptionId: 'S0002',
    contactId: 'C0002',
    periodId: '04.28.2017',
    currency: 'EURO',
    amount: 18,
    status: 'PAID',
    paymentMethod: 'SEPA',
    paymentAccount: 'DE44500105179379967982',
    accountingType: 'INV',
    accountingDate: '2017-03-28',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-03-17',

});

TRANSACTIONS.push({
    transactionId: 'T00010',
    transactionDate: '2017-04-14',
    transactionType: 'PMT',
    subscriptionId: 'S0002',
    contactId: 'C0002',
    periodId: '04.28.2017',
    currency: 'EURO',
    amount: 10.50,
    status: 'PAID',
    paymentMethod: 'SEPA',
    paymentAccount: 'DE44500105179379967982',
    accountingType: 'INV',
    accountingDate: '2017-03-28',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-03-28',

});


TRANSACTIONS.push({
    transactionId: 'T0011',
    transactionDate: '2017-08-28',
    transactionType: 'INV',
    subscriptionId: 'S0003',
    contactId: 'C0003',
    periodId: '09.28.2017',
    currency: 'EUR',
    amount: 612.65,
    status: 'UNPAID',
    createdBy: 'Aldo König',
    createdOn: '2017-08-27',

});


TRANSACTIONS.push({
    transactionId: 'T0012',
    transactionDate: '2017-09-01',
    transactionType: 'PMT',
    subscriptionId: 'S0003',
    contactId: 'C0003',
    periodId: '08.28.2017',
    currency: 'EUR',
    amount: 612.15,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '4539917143290671',
    accountingType: 'INV',
    accountingDate: '2017-08-28',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-08-26',

});


TRANSACTIONS.push({
    transactionId: 'T0013',
    transactionDate: '2017-12-09',
    transactionType: 'INV',
    subscriptionId: 'S0003',
    contactId: 'C0009',
    periodId: '01.09.2018',
    currency: 'EUR',
    amount: 612.15,
    status: 'UNPAID',
    owner: 'CSA',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-12-05',

});


TRANSACTIONS.push({
    transactionId: 'T0014',
    transactionDate: '2017-12-19',
    transactionType: 'PMT',
    subscriptionId: 'S0003',
    contactId: 'C0003',
    periodId: '01.09.2018',
    currency: 'EUR',
    amount: 412.25,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '4539917143290671',
    accountingType : 'INV',
    accountingDate: '2017-12-09',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-12-17',

});

TRANSACTIONS.push({
    transactionId: 'T0015',
    transactionDate: '2018-01-01',
    transactionType: 'PMT',
    subscriptionId: 'S0003',
    contactId: 'C0003',
    periodId: '01.09.2018',
    currency: 'EUR',
    amount: 200,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '4539917143290671',
    accountingType : 'INV',
    accountingDate: '2017-12-09',
    owner: 'MEG',
    createdBy: 'Dirk Ziegler',
    createdOn: '2017-12-30',

});


TRANSACTIONS.push({
    transactionId: 'T0016',
    transactionDate: '2018-01-06',
    transactionType: 'INV',
    subscriptionId: 'S0004',
    contactId: 'C0004',
    periodId: '02.06.2018',
    currency: 'EUR',
    amount: 127.113,
    status: 'UNPAID',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2018-01-04',

});


TRANSACTIONS.push({
    transactionId: 'T0017',
    transactionDate: '2018-01-11',
    transactionType: 'PMT',
    subscriptionId: 'S0004',
    contactId: 'C0004',
    periodId: '02.06.2018',
    currency: 'EUR',
    amount: 127.113,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '5303598209610209',
    accountingType : 'INV',
    accountingDate: '2018-01-06',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2018-01-09',

});

TRANSACTIONS.push({
    transactionId: 'T0018',
    transactionDate: '2018-01-16',
    transactionType: 'INV',
    subscriptionId: 'S0004',
    contactId: 'C0004',
    periodId: '02.16.2018',
    currency: 'EUR',
    amount: 127.113,
    status: 'UNPAID',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2018-01-09',

});

TRANSACTIONS.push({
    transactionId: 'T0019',
    transactionDate: '2018-01-18',
    transactionType: 'PMT',
    subscriptionId: 'S0004',
    contactId: 'C0004',
    periodId: '02.16.2018',
    currency: 'EUR',
    amount: 107.113,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '5303598209610209',
    accountingType : 'INV',
    accountingDate: '2018-01-16',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2018-01-16',

});

TRANSACTIONS.push({
    transactionId: 'T0020',
    transactionDate: '2018-01-19',
    transactionType: 'PMT',
    subscriptionId: 'S0004',
    contactId: 'C0004',
    periodId: '02.16.2018',
    currency: 'EUR',
    amount: 27.113,
    status: 'PAID',
    paymentMethod: 'CC',
    paymentAccount: '5303598209610209',
    accountingType : 'INV',
    accountingDate: '2017-12-09',
    owner: 'PPS',
    createdBy: 'Dirk Ziegler',
    createdOn: '2018-01-15',
});

