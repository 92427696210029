import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeWords'
})
export class CapitalizeWordsPipe implements PipeTransform {

  transform(value: string) {
    if (value) {
        const words = value.match(/[A-Za-z][a-z]*/g) || [];
        value = words.map(this.capitalize).join(' ');
        return value;
    } else {
      return '';
    }
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

}
