import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditLogLineModel, AuditLogModel } from '../models/auditlog.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

export class AuditlogService extends OdooHttpBaseService {
    baseUrl = 'auditlog';

    constructor(injector: Injector) {
        super(injector);
    }

    getBySubscription(id: number): Observable<AuditLogModel[]> {
        const observable = new Observable<AuditLogModel[]>(subscriber => {
            this._post(`${this.baseUrl}/subscription/${id}/get`, {}).subscribe(items => {
                if (items != null) {
                    const auditlogs = AuditLogModel.getInstancesFromResponse(items);
                    this.getLinesForCustomerUpdate(auditlogs, (auditlogs) => {
                        const validAuditlogs = [];
                        if (auditlogs) {
                            auditlogs.forEach(auditLog => {
                                if (auditLog.description) {
                                    validAuditlogs.push(auditLog);
                                }
                            })
                        }
                        subscriber.next(validAuditlogs)
                    })
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getByContact(id: number): Observable<AuditLogModel[]> {
        const observable = new Observable<AuditLogModel[]>(subscriber => {
            this._post(`${this.baseUrl}/contact/${id}/get`, {}).subscribe(items => {
                if (items != null) {
                    const auditlogs = AuditLogModel.getInstancesFromResponse(items);
                    this.getLinesForCustomerUpdate(auditlogs, (auditlogs) => {
                        const validAuditlogs = [];
                        if (auditlogs) {
                            auditlogs.forEach(auditLog => {
                                if (auditLog.description) {
                                    validAuditlogs.push(auditLog);
                                }
                            })
                        }
                        subscriber.next(validAuditlogs);
                    })
                    subscriber.next(auditlogs);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getLinesForCustomerUpdate(auditlogs: AuditLogModel[], callback: any, index: number = 0) {
        if (index >= auditlogs.length) {
            callback(auditlogs)
        } else {
            const auditLog = auditlogs[index];
            index = index + 1
            if (auditLog.messageId == 'customerInfoMsg_CUSTOMER_UPDATED') {
                this.getLines(auditLog.id).subscribe(lines => {
                    let description = 'Client modifié. '
                    if (lines) {
                        if (lines.length < 15) {
                            lines.forEach(line => {
                                let oldValueText = '';
                                let newValueText = '';
                                if (line.oldValueText) {
                                    oldValueText = line.oldValueText
                                }
                                if (line.newValueText) {
                                    newValueText = line.newValueText
                                }
                                description = description + line.fieldDescription + ": '" + oldValueText + "' to '" + newValueText + "', "
                            })
                            auditLog.description = description;
                            auditLog.messageId = null;
                        } else {
                            auditLog.description = null;
                            console.log(auditLog);
                        }
                        
                    }
                    this.getLinesForCustomerUpdate(auditlogs, callback, index);

                });
            } else {
                this.getLinesForCustomerUpdate(auditlogs, callback, index);
            }
        }
    }


    getLines(id: number): Observable<AuditLogLineModel[]> {
        const observable = new Observable<AuditLogLineModel[]>(subscriber => {
            this._post(`${this.baseUrl}/${id}/lines`, {}).subscribe(items => {
                if (items != null) {
                    const auditlogLines = AuditLogLineModel.getInstancesFromResponse(items);
                    subscriber.next(auditlogLines);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

}
