import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiRowRendererComponent } from './ui-row-renderer/ui-row-renderer.component';
import { UiTableRendererComponent } from './ui-table-renderer/ui-table-renderer.component';
import { UiRendererComponent } from './ui-renderer.component';
import { UiCellRendererComponent } from './ui-cell-renderer/ui-cell-renderer.component';
import { UiLabelComponent } from './ui-components/ui-label/ui-label.component';
import { UiTextAreaComponent } from './ui-components/ui-text-area/ui-text-area.component';
import { UiImageComponent } from './ui-components/ui-image/ui-image.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { CapitalizeWordsPipe } from '../../../pipes/capitalize-words.pipe';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule
    ],
    declarations: [
        UiRendererComponent,
        UiTableRendererComponent,
        UiRowRendererComponent,
        UiCellRendererComponent,
        UiLabelComponent,
        UiTextAreaComponent,
        UiImageComponent
    ],
    exports: [
        UiRendererComponent
    ],
    providers: [
        CapitalizeWordsPipe
    ],
    entryComponents: [
        UiLabelComponent,
        UiTextAreaComponent,
        UiImageComponent
    ]
})
export class UiRendererModule {

}
