import { Pipe, PipeTransform } from '@angular/core';
import { AuditLogModel } from '../models/auditlog.model';
import { InvoiceModel } from '../models/invoice.model';
import { SubscriptionModel } from '../models/subscription.model';

@Pipe({
    name: 'auditlogs'
})
export class AuditLogsFilter  implements PipeTransform {
    transform(auditlogs: AuditLogModel[], subscriptions: SubscriptionModel[]) {
        const filtered: AuditLogModel[] = [];
        if (auditlogs != null && auditlogs.length > 0) {
            auditlogs.forEach(auditlog => {
                if (subscriptions && subscriptions.length > 0) {
                    for (let s = 0; s < subscriptions.length; s++) {
                        const subscription = subscriptions[s];
                        if (auditlog.subscriptionId && subscription.id === auditlog.subscriptionId) {
                            filtered.push(auditlog);
                            break;
                        }
                    }
                } else {
                    filtered.push(auditlog);
                }
            });
        }
        return filtered;
    }
}
