import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceModel } from '../models/invoice.model';
import { PaymentRegistryModel } from '../models/payment-registry.model';
import { PaymentModel } from '../models/payment.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class PaymentService extends OdooHttpBaseService {
    baseUrl = 'payment';

    constructor(injector: Injector) {
        super(injector);
    }

    getPartnerPayments(partnerId: number): Observable<PaymentModel[]> {
        const observable = new Observable<PaymentModel[]>(subscriber => {
            this._post(`${this.baseUrl}/get_partner_journals`, {partnerId: partnerId}).subscribe(items => {
                if (items != null) {
                    const payments = PaymentModel.getInstancesFromResponse(items);
                    subscriber.next(payments);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getPartnerPaymentRegistries(partnerId: number): Observable<PaymentRegistryModel[]> {
        const observable = new Observable<PaymentRegistryModel[]>(subscriber => {
            this._post(`${this.baseUrl}/contact/get_payment_registries`, {partnerId: partnerId}).subscribe(items => {
                if (items != null) {
                    const paymentRegistries = PaymentRegistryModel.getInstancesFromResponse(items);
                    subscriber.next(paymentRegistries);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    

}
