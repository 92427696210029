import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceModel } from '../models/invoice.model';
import { OdooHttpBaseService } from './odoo-http-base.service';

@Injectable()
export class InvoiceService extends OdooHttpBaseService {
    baseUrl = 'invoice';

    constructor(injector: Injector) {
        super(injector);
    }

    search(criteria: any[]): Observable<InvoiceModel[]> {
        const observable = new Observable<InvoiceModel[]>(subscriber => {
            this._post(`${this.baseUrl}/search`, {criteria: criteria, page: 1}).subscribe(items => {
                if (items != null) {
                    const invoices = InvoiceModel.getInstancesFromResponse(items);
                    subscriber.next(invoices);
                } else {
                    subscriber.next(null);
                }
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    getPaidInvoices(id: number): Observable<InvoiceModel[]> {
        const request = {
            'subscriptionId': id
        };

        const observable = new Observable<InvoiceModel[]>(subscriber => {
            this._post(`${this.baseUrl}/get_invoices_for_refund`, request).subscribe(response => {
                let invoices: InvoiceModel[] = [];
                if (response) {
                    invoices = InvoiceModel.getInstancesFromResponse(response);
                }
                subscriber.next(invoices);
             }, error => {
                 subscriber.error(error);
            });
        });

        return observable;
    }

    refundInvoices(ids: number[]): Observable<any> {
        const request = {
            'invoiceIds': ids
        };
        const observable = new Observable<any>(subscriber => {
            this._post(`${this.baseUrl}/refund_invoices`, request).subscribe(response => {
                subscriber.next(response);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }

    cancelInvoice(id: any): Observable<any> {
        const request = {
            'id': id
        };
        const observable = new Observable<any>(subscriber => {
            this._post(`${this.baseUrl}/cancel/`+id, request).subscribe(response => {
                subscriber.next(response);
            }, error => {
                subscriber.error(error);
            });
        });
        return observable;
    }
}
