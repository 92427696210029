import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addWhiteSpace'
})
export class AddWhiteSpacePipe implements PipeTransform {

  transform(value: string) {
    if (value) {
      return value.replace(/,/gi, ', ');
    } else {
      return '';
    }
  }
}
